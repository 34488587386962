import React from 'react';

const SVGLogoMerge = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1366 296"
    fill="none"
  >
    <path
      d="M605.888 218.938V133.197C605.888 115.236 601.786 100.103 583.506 100.103C565.844 100.103 557.342 116.49 557.342 134.152V218.938H505.65V129.413C505.65 111.453 498.72 100.103 483.269 100.103C466.881 100.103 457.423 114.917 457.423 133.834V218.938H405.412V61.9708H455.531V83.7149H456.168C463.416 70.4733 479.485 57.5503 503.141 57.5503C525.841 57.5503 541.591 67.3272 550.432 84.3521C560.827 67.9644 577.234 57.5503 600.551 57.5503C643.104 57.5503 657.6 92.2174 657.6 121.847V218.938H605.888V218.938Z"
      fill="#121151"
    />
    <path
      d="M849.991 154.622H736.193C737.766 171.965 755.11 184.251 773.708 184.251C790.095 184.251 801.445 177.322 808.375 167.863L844.316 190.563C829.501 211.989 804.91 223.976 773.07 223.976C725.779 223.976 686.691 194.028 686.691 141.082C686.691 89.7082 723.887 56.9128 771.477 56.9128C817.813 56.9128 850.29 88.7524 850.29 142.336C850.29 146.438 850.29 150.838 849.991 154.622ZM802.7 122.484C802.7 106.096 792.306 92.5357 771.816 92.5357C751.963 92.5357 737.447 106.414 736.193 122.484H802.7Z"
      fill="#121151"
    />
    <path
      d="M981.233 104.205C976.514 102.95 972.093 102.632 967.991 102.632C940.254 102.632 931.114 125.331 931.114 138.254V218.958H879.422V61.9908H929.222V84.6906H929.859C937.745 68.9202 953.177 57.5902 972.412 57.5902C976.514 57.5902 980.934 57.9088 983.443 58.8447L981.233 104.205Z"
      fill="#121151"
    />
    <path
      d="M1080.95 294.585C1053.53 294.585 1021.37 285.764 1000.25 269.993L1026.09 233.116C1041.23 246.357 1060.46 252.968 1078.1 252.968C1108.37 252.968 1122.55 235.306 1122.55 209.162V199.703H1121.61C1111.84 212.308 1094.81 220.511 1074.02 220.511C1026.43 220.511 998.993 183.315 998.993 139.807C998.993 96.2993 1026.41 57.5304 1073.7 57.5304C1093.26 57.5304 1112.79 65.4156 1123.82 82.1219H1124.46V61.9509H1173.32V204.104C1173.3 265.593 1136.75 294.585 1080.95 294.585ZM1085.99 99.784C1062.67 99.784 1049.11 117.745 1049.11 139.509C1049.11 160.317 1062.67 179.532 1085.99 179.532C1109.63 179.532 1124.12 160.934 1124.12 139.509C1124.14 118.063 1109.33 99.784 1085.99 99.784Z"
      fill="#121151"
    />
    <path
      d="M1365.7 154.622H1251.9C1253.47 171.965 1270.81 184.251 1289.41 184.251C1305.8 184.251 1317.15 177.322 1324.08 167.863L1360.02 190.563C1345.21 211.989 1320.61 223.976 1288.77 223.976C1241.48 223.976 1202.4 194.028 1202.4 141.082C1202.4 89.7082 1239.59 56.9128 1287.18 56.9128C1333.52 56.9128 1365.99 88.7524 1365.99 142.336C1366.01 146.438 1366.01 150.838 1365.7 154.622ZM1318.42 122.484C1318.42 106.096 1308.03 92.5357 1287.54 92.5357C1267.69 92.5357 1253.17 106.414 1251.92 122.484H1318.42Z"
      fill="#121151"
    />
    <path
      d="M0 281.88V14.3606C0 3.13012 12.5248 -3.58023 21.8636 2.65227L222.897 136.761C231.26 142.336 231.24 154.622 222.877 160.177L21.8436 293.589C12.4849 299.801 0 293.111 0 281.88Z"
      fill="#121151"
    />
    <path
      d="M93.4874 138.433L294.839 2.73206C305.373 -4.35667 319.55 3.17014 319.55 15.8741V280.089C319.55 296.855 296.91 302.151 289.483 287.118L234.266 175.291C231.897 170.492 227.018 167.445 221.662 167.445H102.348C86.6974 167.445 80.5047 147.175 93.4874 138.433Z"
      fill="url(#paint0_linear_589_278)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_589_278"
        x1="86.4642"
        y1="147.983"
        x2="319.556"
        y2="147.983"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#121151" />
        <stop offset="0.0612" stopColor="#1D1C70" />
        <stop offset="0.1492" stopColor="#2B2A95" />
        <stop offset="0.2446" stopColor="#3736B5" />
        <stop offset="0.347" stopColor="#403FD0" />
        <stop offset="0.4592" stopColor="#4847E4" />
        <stop offset="0.5863" stopColor="#4D4CF2" />
        <stop offset="0.7409" stopColor="#504FFA" />
        <stop offset="1" stopColor="#5150FD" />
      </linearGradient>
    </defs>
  </svg>
);

export default SVGLogoMerge;
