import formTextData from './components/Form/lang';

const contactTextDataES = {
  ...formTextData.es,
};
const contactTextDataEN = {
  ...formTextData.en,
};

export { contactTextDataEN, contactTextDataES };
