import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import NavItem from './components/NavItem';
import { SVGLogoMerge } from 'assets/svg';
import LanguageSelector from '../LanguageSelector';

const SidebarNav = ({ pages }) => {

  const {
    landings: landingPages
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="theFront"
          width={{ xs: 100, md: 120 }}
        >
          <SVGLogoMerge />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        {landingPages.map((item, index) => (
          <Box key={index} >
            <NavItem item={item} />
          </Box>
        ))}
        <LanguageSelector />
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
