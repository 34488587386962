import React from 'react';

const SVGAriadnaLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="121"
    height="41"
    viewBox="0 0 121 41"
    fill="none"
  >
    <path
      d="M37.0425 8.89966C36.3159 8.89966 35.7788 9.47822 35.7788 10.261V28.0603C35.7788 28.8431 36.3159 29.4216 37.0425 29.4216C37.7691 29.4216 38.3062 28.8431 38.3062 28.0603V10.278C38.3062 9.58032 37.8481 8.89966 37.0425 8.89966Z"
      fill="#58595B"
    />
    <path
      d="M80.2453 3.62451C80.2453 2.94385 79.7083 2.26318 78.9816 2.26318C78.3498 2.26318 77.7179 2.84175 77.7179 3.62451V12.2349C75.9961 10.1759 73.4529 8.91666 70.6412 8.91666C65.381 8.91666 61.116 13.5111 61.116 19.1777C61.116 24.8442 65.381 29.4386 70.6412 29.4386C75.9013 29.4386 80.1664 24.8442 80.1664 19.1777C80.1664 19.0756 80.1664 19.0756 80.1664 18.9905L80.2453 3.62451ZM77.7969 19.1606C77.7969 19.3648 77.7969 19.3648 77.7969 19.1606C77.7969 23.4658 74.7166 26.7841 70.8149 26.7841C66.9132 26.7841 63.8329 23.4658 63.8329 19.2627C63.8329 15.0596 66.9132 11.8265 70.8149 11.8265C74.5429 11.8265 77.7179 15.0596 77.7969 19.0585V19.1606Z"
      fill="#58595B"
    />
    <path
      d="M91.2239 8.89966C86.0427 8.89966 81.7777 13.392 81.6987 18.9735V19.0756C81.6987 19.0756 81.6987 19.0756 81.6987 19.1777V28.1794C81.6987 28.9622 82.2358 29.5408 82.9624 29.5408C83.6891 29.5408 84.2261 28.9622 84.2261 28.1794V19.1606C84.3209 15.0597 87.4012 11.7244 91.2081 11.7244C95.0151 11.7244 98.1901 15.0426 98.1901 19.1606V28.1624C98.1901 28.8431 98.7272 29.5237 99.4538 29.5237C100.086 29.5237 100.718 28.9452 100.718 28.1624V19.1606C100.765 13.5962 96.4841 8.89966 91.2239 8.89966Z"
      fill="#58595B"
    />
    <path
      d="M31.0398 8.89966C25.7797 8.89966 21.5936 13.392 21.5146 18.9735V19.0756C21.5146 19.0756 21.5146 19.0756 21.5146 19.1777V28.1794C21.5146 28.9622 22.0517 29.5408 22.7784 29.5408C23.505 29.5408 24.0421 28.9622 24.0421 28.1794V19.1606C24.1368 15.0597 27.2171 11.7244 31.024 11.7244C31.024 11.7244 32.7459 11.8265 32.7459 10.261C32.6827 8.89966 31.0398 8.89966 31.0398 8.89966Z"
      fill="#58595B"
    />
    <path
      d="M9.5252 8.89966C4.26501 8.89966 0 13.5962 0 19.1606C0 24.7251 4.26501 29.4216 9.5252 29.4216C11.7051 29.4216 13.6954 28.6389 15.3382 27.3626L16.9653 29.1153C17.139 29.3025 17.5023 29.5067 17.7867 29.5067C18.5133 29.5067 19.0504 28.9282 19.0504 28.1454V19.535C19.0504 19.535 19.0504 19.535 19.0504 19.4329V19.3308C19.0662 13.5962 14.8012 8.89966 9.5252 8.89966ZM9.61997 26.8011C5.81305 26.8011 2.63799 23.4829 2.63799 19.2798C2.63799 15.0767 5.81305 11.8435 9.61997 11.8435C13.4269 11.8435 16.602 15.1618 16.602 19.3648C16.602 23.5679 13.5217 26.8011 9.61997 26.8011Z"
      fill="#58595B"
    />
    <path
      d="M50.201 8.89966C44.9408 8.89966 40.6758 13.4941 40.6758 19.1606C40.6758 24.8272 45.0356 29.4216 50.201 29.4216C52.3809 29.4216 54.3712 28.6389 56.014 27.3626L57.6411 29.1153C57.8148 29.3025 58.1781 29.5067 58.4625 29.5067C59.1891 29.5067 59.7262 28.9282 59.7262 28.1454V19.535C59.7262 19.535 59.7262 19.535 59.7262 19.4329V19.3308C59.7262 13.5962 55.4612 8.89966 50.201 8.89966ZM50.201 26.8011C46.3941 26.8011 43.219 23.4829 43.219 19.2798C43.219 15.0767 46.2993 11.7584 50.201 11.7584C54.1027 11.7584 57.183 15.0767 57.183 19.2798C57.2777 23.4658 54.1974 26.8011 50.201 26.8011Z"
      fill="#58595B"
    />
    <path
      d="M111.38 8.89966C106.12 8.89966 101.855 13.4941 101.855 19.1606C101.855 24.8272 106.12 29.4216 111.38 29.4216C113.56 29.4216 115.55 28.6389 117.193 27.3626L118.82 29.1153C118.994 29.3025 119.357 29.5067 119.642 29.5067C120.368 29.5067 120.905 28.9282 120.905 28.1454V19.535C120.905 19.535 120.905 19.535 120.905 19.4329V19.3308C120.905 13.5962 116.735 8.89966 111.38 8.89966ZM111.475 26.8011C107.668 26.8011 104.493 23.4829 104.493 19.2798C104.493 15.0767 107.573 11.7584 111.475 11.7584C115.282 11.7584 118.552 15.0767 118.552 19.2798C118.457 23.4658 115.377 26.8011 111.475 26.8011Z"
      fill="#58595B"
    />
    <path
      d="M114.381 3.13105C114.381 4.88375 113.117 6.2621 111.475 6.2621C109.848 6.2621 108.568 4.90077 108.568 3.13105C108.568 1.36133 109.832 0 111.475 0C113.102 0 114.381 1.46342 114.381 3.13105Z"
      fill="#F1C731"
    />
    <path
      d="M107.652 3.13105C107.652 4.88375 106.388 6.2621 104.746 6.2621C103.103 6.2621 101.839 4.90077 101.839 3.13105C101.839 1.36133 103.103 0 104.746 0C106.388 0 107.652 1.46342 107.652 3.13105Z"
      fill="#E9B230"
    />
    <path
      d="M121 3.13105C121 4.88375 119.736 6.2621 118.094 6.2621C116.467 6.2621 115.187 4.90077 115.187 3.13105C115.187 1.36133 116.451 0 118.094 0C119.736 0 121 1.46342 121 3.13105Z"
      fill="#F3D931"
    />
    <path
      d="M21.5146 36.3814C21.5146 34.918 22.6046 33.5397 24.0579 33.5397C24.8793 33.5397 25.4163 33.9311 25.8744 34.3224C25.9692 34.3224 25.9692 34.4245 25.9692 34.5096C25.9692 34.6968 25.7955 34.901 25.6059 34.901C25.5111 34.901 25.4321 34.901 25.4321 34.7989C24.974 34.6117 24.6107 34.3054 24.0737 34.3054C23.0785 34.3054 22.2571 35.1903 22.2571 36.3644C22.2571 37.5386 22.9837 38.4234 24.0737 38.4234C24.6107 38.4234 25.1636 38.2362 25.4321 37.7428C25.4321 37.7428 25.5269 37.6407 25.6059 37.6407C25.7797 37.6407 25.9692 37.8278 25.9692 38.032C25.9692 38.2362 25.9692 38.2192 25.8744 38.2192C25.4163 38.7127 24.7845 39.1041 23.9631 39.1041C22.6046 39.3083 21.5146 37.947 21.5146 36.3814Z"
      fill="#59595B"
    />
    <path
      d="M26.3167 36.3814C26.3167 34.918 27.4066 33.5397 28.8599 33.5397C30.4079 33.5397 31.4031 34.8159 31.4031 36.2793C31.4031 37.7428 30.3131 39.019 28.7651 39.019C27.4066 39.3083 26.3167 38.0491 26.3167 36.3814ZM30.7712 36.3814C30.7712 35.2073 29.9498 34.3224 28.9546 34.3224C27.8647 34.3224 27.1381 35.2073 27.1381 36.3814C27.1381 37.5556 27.8647 38.4404 28.9546 38.4404C29.9498 38.4234 30.7712 37.5556 30.7712 36.3814Z"
      fill="#59595B"
    />
    <path
      d="M32.2247 34.1351C32.2247 33.9479 32.3984 33.6416 32.588 33.6416C32.7775 33.6416 33.0461 33.8288 33.0461 34.1351V34.7136C33.4094 34.1351 33.7727 33.6416 34.6731 33.6416C35.4945 33.6416 35.9368 34.1351 36.3001 34.7136C36.6634 34.1351 37.1215 33.6416 38.0219 33.6416C39.2066 33.6416 39.8385 34.4244 39.8385 35.7857V38.8146C39.8385 39.0018 39.6647 39.3081 39.4752 39.3081C39.2856 39.3081 39.0171 39.1209 39.0171 38.8146V36.075C39.0171 35.0029 38.48 34.4073 37.7534 34.4073C37.0268 34.4073 36.3949 34.9859 36.3949 36.075V38.8146C36.3949 39.0018 36.2211 39.3081 35.9368 39.3081C35.6525 39.3081 35.5735 39.1209 35.5735 38.8146V36.075C35.5735 35.0029 35.0364 34.4073 34.3098 34.4073C33.5831 34.4073 32.9513 35.088 32.9513 36.1601V38.8997C32.9513 39.0869 32.8565 39.3932 32.588 39.3932C32.3194 39.3932 32.1299 39.206 32.1299 38.8997V34.016H32.2247V34.1351Z"
      fill="#59595B"
    />
    <path
      d="M40.9441 34.1351C40.9441 33.9479 41.1179 33.6416 41.3075 33.6416C41.497 33.6416 41.7656 33.8288 41.7656 34.1351V34.7136C42.1289 34.1351 42.4922 33.6416 43.3926 33.6416C44.293 33.6416 44.6563 34.1351 45.0196 34.7136C45.3829 34.1351 45.841 33.6416 46.7414 33.6416C47.9261 33.6416 48.558 34.4244 48.558 35.7857V38.8146C48.558 39.0018 48.3842 39.3081 48.1947 39.3081C48.0051 39.3081 47.7366 39.1209 47.7366 38.8146V36.075C47.7366 35.0029 47.1995 34.4073 46.4729 34.4073C45.7462 34.4073 45.1144 34.9859 45.1144 36.075V38.8146C45.1144 39.0018 44.9406 39.3081 44.6563 39.3081C44.4825 39.3081 44.293 39.1209 44.293 38.8146V36.075C44.293 35.0029 43.7559 34.4073 43.0293 34.4073C42.3026 34.4073 41.6708 35.088 41.6708 36.1601V38.8997C41.6708 39.0869 41.576 39.3932 41.3075 39.3932C41.0389 39.3932 40.8494 39.1039 40.8494 38.8997V34.016H40.9441V34.1351Z"
      fill="#59595B"
    />
    <path
      d="M53.8339 38.7297C53.8339 38.9169 53.7391 39.2232 53.4706 39.2232C53.2021 39.2232 53.1073 39.036 53.1073 38.7297V38.1511C52.744 38.7297 52.2859 39.2232 51.3855 39.2232C50.2007 39.2232 49.4741 38.3383 49.4741 37.0791V34.0501C49.4741 33.8629 49.5689 33.5566 49.8374 33.5566C50.106 33.5566 50.2955 33.7438 50.2955 34.0501V36.7898C50.2955 37.8618 50.8326 38.4574 51.654 38.4574C52.4754 38.4574 53.2021 37.7767 53.2021 36.7047V33.965C53.2021 33.7779 53.2968 33.4716 53.5654 33.4716C53.7391 33.4716 53.9287 33.6587 53.9287 33.965C53.8339 33.8289 53.8339 38.7297 53.8339 38.7297Z"
      fill="#59595B"
    />
    <path
      d="M54.9241 34.1351C54.9241 33.9479 55.0979 33.6416 55.2874 33.6416C55.477 33.6416 55.7455 33.8288 55.7455 34.1351V34.7136C56.1088 34.1351 56.5669 33.6416 57.4673 33.6416C58.6521 33.6416 59.3787 34.5265 59.3787 35.7857V38.8146C59.3787 39.0018 59.2049 39.3081 59.0154 39.3081C58.8258 39.3081 58.5573 39.1209 58.5573 38.8146V36.075C58.5573 35.0029 58.0202 34.4073 57.1988 34.4073C56.3774 34.4073 55.6508 35.088 55.6508 36.1601V38.8997C55.6508 39.0869 55.556 39.3932 55.2874 39.3932C55.0189 39.3932 54.8293 39.206 54.8293 38.8997C54.9241 38.9167 54.9241 34.1351 54.9241 34.1351Z"
      fill="#59595B"
    />
    <path
      d="M60.2788 31.9741C60.2788 31.7869 60.4526 31.4806 60.7369 31.4806C60.9107 31.4806 61.195 31.5827 61.195 31.9741V32.0762C61.195 32.2634 61.0212 32.5697 60.7369 32.5697C60.5631 32.5697 60.2788 32.3825 60.2788 32.0762V31.9741ZM60.3578 34.1352C60.3578 33.948 60.5315 33.6417 60.7211 33.6417C60.9107 33.6417 61.1792 33.8289 61.1792 34.1352V38.8317C61.1792 39.0189 61.0844 39.3252 60.8159 39.3252C60.6421 39.3252 60.3578 39.138 60.3578 38.8317V34.1352Z"
      fill="#59595B"
    />
    <path
      d="M62.0007 36.3814C62.0007 34.918 63.0907 33.5397 64.5439 33.5397C65.3654 33.5397 65.9024 33.9311 66.3605 34.3224C66.4553 34.3224 66.4553 34.4245 66.4553 34.5096C66.4553 34.6968 66.2815 34.901 66.092 34.901C65.9972 34.901 65.9182 34.901 65.9182 34.7989C65.5549 34.4075 65.1916 34.1182 64.5597 34.1182C63.4698 34.1182 62.7432 35.0031 62.7432 36.1772C62.7432 37.3514 63.4698 38.2362 64.5597 38.2362C65.0968 38.2362 65.6497 38.0491 65.9182 37.5556C65.9182 37.5556 66.013 37.4535 66.092 37.4535C66.2657 37.4535 66.4553 37.6407 66.4553 37.8449C66.4553 38.0491 66.4553 38.032 66.3605 38.032C65.9024 38.5255 65.2706 38.9169 64.4492 38.9169C63.0907 39.3083 62.0007 37.947 62.0007 36.3814Z"
      fill="#59595B"
    />
    <path
      d="M66.803 37.5555C66.803 36.3814 67.6244 35.8028 68.9829 35.8028C69.6147 35.8028 70.0728 35.9049 70.5309 35.99V35.8028C70.5309 34.918 69.9938 34.3394 68.9829 34.3394C68.4458 34.3394 67.9877 34.4415 67.7192 34.7308H67.6244C67.4506 34.7308 67.2611 34.6287 67.2611 34.3394C67.2611 34.2373 67.3559 33.948 67.4348 33.948C67.9719 33.7608 68.43 33.5566 69.0619 33.5566C69.7885 33.5566 70.3256 33.7438 70.6889 34.1352C71.0522 34.5266 71.226 35.0201 71.226 35.8028V38.8318C71.226 39.019 71.1312 39.2232 70.8626 39.2232C70.6889 39.2232 70.4993 39.1211 70.4993 38.8318V38.3383C70.136 38.8318 69.5042 39.2232 68.6827 39.2232C67.8139 39.3082 66.803 38.7297 66.803 37.5555ZM70.6257 37.0621V36.5686C70.2624 36.4665 69.8043 36.3814 69.0777 36.3814C68.0825 36.3814 67.5296 36.8749 67.5296 37.5555C67.5296 38.2362 68.0667 38.6276 68.7933 38.6276C69.8043 38.6276 70.6257 38.049 70.6257 37.0621Z"
      fill="#59595B"
    />
    <path
      d="M72.5211 37.6577V34.4246H72.063C71.8893 34.4246 71.6997 34.3225 71.6997 34.0332C71.6997 33.846 71.7945 33.6418 72.063 33.6418H72.5211V32.3656C72.5211 32.1784 72.6159 31.8721 72.8844 31.8721C73.0582 31.8721 73.3425 32.0593 73.3425 32.3656V33.6418H74.6062C74.78 33.6418 74.9696 33.7439 74.9696 34.0332C74.9696 34.3225 74.8748 34.4246 74.6062 34.4246H73.3425V37.5556C73.3425 38.2363 73.7058 38.4405 74.1639 38.4405C74.3377 38.4405 74.622 38.3384 74.622 38.3384C74.7958 38.3384 74.9853 38.4405 74.9853 38.7297C74.9853 38.8318 74.8906 38.9169 74.8116 39.1211C74.6378 39.2232 74.3535 39.2232 74.085 39.2232C73.074 39.1211 72.5211 38.8148 72.5211 37.6577Z"
      fill="#59595B"
    />
    <path
      d="M75.5222 31.9741C75.5222 31.7869 75.696 31.4806 75.9803 31.4806C76.1541 31.4806 76.4384 31.5827 76.4384 31.9741V32.0762C76.4384 32.2634 76.2646 32.5697 75.9803 32.5697C75.8066 32.5697 75.5222 32.3825 75.5222 32.0762V31.9741ZM75.617 34.1352C75.617 33.948 75.7908 33.6417 75.9803 33.6417C76.1541 33.6417 76.4384 33.8289 76.4384 34.1352V38.8317C76.4384 39.0189 76.3436 39.3252 76.0751 39.3252C75.9013 39.3252 75.617 39.138 75.617 38.8317V34.1352Z"
      fill="#59595B"
    />
    <path
      d="M77.2441 36.3814C77.2441 34.918 78.3341 33.5397 79.7874 33.5397C81.3354 33.5397 82.3306 34.8159 82.3306 36.2793C82.3306 37.7428 81.2406 39.019 79.6926 39.019C78.3341 39.3083 77.2441 38.0491 77.2441 36.3814ZM81.6039 36.3814C81.6039 35.2073 80.7825 34.3224 79.7874 34.3224C78.6974 34.3224 77.9708 35.2073 77.9708 36.3814C77.9708 37.5556 78.6974 38.4404 79.7874 38.4404C80.8773 38.4234 81.6039 37.5556 81.6039 36.3814Z"
      fill="#59595B"
    />
    <path
      d="M83.231 34.1351C83.231 33.9479 83.4048 33.6416 83.5943 33.6416C83.7839 33.6416 84.0524 33.8288 84.0524 34.1351V34.7136C84.4157 34.1351 84.8738 33.6416 85.7742 33.6416C86.9589 33.6416 87.6856 34.5265 87.6856 35.7857V38.8146C87.6856 39.0018 87.5118 39.3081 87.3223 39.3081C87.1327 39.3081 86.8642 39.1209 86.8642 38.8146V36.075C86.8642 35.0029 86.3271 34.4073 85.5057 34.4073C84.6843 34.4073 83.9576 35.088 83.9576 36.1601V38.8997C83.9576 39.0869 83.8629 39.3932 83.5943 39.3932C83.4206 39.3932 83.1362 39.206 83.1362 38.8997C83.231 38.9167 83.231 34.1351 83.231 34.1351Z"
      fill="#59595B"
    />
    <path
      d="M88.2383 38.6276C88.1436 38.6276 88.1436 38.5255 88.1436 38.2362C88.1436 37.9469 88.2383 37.8448 88.5069 37.8448C88.6016 37.8448 88.6017 37.8448 88.6806 37.9469C89.2177 38.3383 89.7706 38.5255 90.3077 38.5255C90.9395 38.5255 91.3976 38.1341 91.3976 37.7427C91.3976 37.1642 90.7657 36.96 90.1339 36.8579C89.4073 36.6707 88.5069 36.2793 88.5069 35.3945C88.5069 34.5096 89.2335 33.931 90.2287 33.931C90.7657 33.931 91.3186 34.1182 91.8557 34.4245C91.9505 34.5266 92.0295 34.6117 92.0295 34.8159C92.0295 35.0201 91.9347 35.2073 91.6661 35.2073C91.5714 35.2073 91.5714 35.2073 91.4924 35.2073C90.9553 34.6287 90.4972 34.5266 90.1339 34.5266C89.502 34.5266 89.1387 34.918 89.1387 35.3094C89.1387 35.8029 89.7706 35.99 90.4024 36.1942C91.1291 36.3814 92.0295 36.7728 92.0295 37.6577C92.0295 38.7297 91.3028 39.3253 90.2129 39.3253C89.502 39.1211 88.8702 38.9169 88.2383 38.6276Z"
      fill="#59595B"
    />
    <path
      d="M95.4103 40.3803C95.2365 40.2782 94.9522 39.9889 94.9522 39.8017C94.9522 39.4103 95.3155 39.1211 95.584 39.1211C95.6788 39.1211 95.7578 39.1211 95.7578 39.1211C96.2159 39.3082 96.5792 39.5124 97.1163 39.5124C98.1115 39.5124 98.6643 38.9339 98.6643 38.049V37.8618C98.2062 38.4404 97.6692 38.7467 96.9425 38.7467C95.7578 38.7467 94.5889 37.8618 94.5889 36.1091C94.5889 34.4415 95.6788 33.4716 96.9425 33.4716C97.7639 33.4716 98.2062 33.8629 98.6643 34.2543C98.6643 33.7608 99.0276 33.4716 99.391 33.4716C99.8491 33.4716 100.118 33.8629 100.118 34.2543V37.8788C100.118 38.9509 99.9438 39.6316 99.4857 40.0229C98.9487 40.6015 98.301 40.8057 97.2111 40.8057C96.5792 40.7717 95.9474 40.6866 95.4103 40.3803ZM98.7591 36.0921C98.7591 35.3093 98.222 34.8159 97.4954 34.8159C96.7688 34.8159 96.2317 35.3944 96.2317 36.0921C96.2317 36.8749 96.7688 37.3684 97.4954 37.3684C98.222 37.3684 98.7591 36.8749 98.7591 36.0921Z"
      fill="#59595B"
    />
    <path
      d="M100.844 34.2203C100.844 33.7268 101.207 33.4375 101.571 33.4375C102.029 33.4375 102.297 33.8289 102.297 34.2203V34.4075C102.471 33.8289 102.834 33.3354 103.292 33.3354C103.751 33.3354 104.019 33.7268 104.019 34.1182C104.019 34.6117 103.845 34.7989 103.482 34.7989C102.755 34.9861 102.297 35.5816 102.297 36.7558V38.2192C102.297 38.7127 101.934 39.002 101.571 39.002C101.113 39.002 100.844 38.6106 100.844 38.2192V34.2203Z"
      fill="#59595B"
    />
    <path
      d="M104.019 36.3814C104.019 34.7138 105.204 33.5397 106.831 33.5397C108.458 33.5397 109.642 34.8159 109.642 36.3814C109.642 38.0491 108.458 39.2232 106.831 39.2232C105.204 39.3083 104.019 37.947 104.019 36.3814ZM108.205 36.3814C108.205 35.5987 107.668 34.7138 106.846 34.7138C106.025 34.7138 105.488 35.3945 105.488 36.1772C105.488 36.96 106.025 37.6407 106.846 37.6407C107.652 37.947 108.205 37.1642 108.205 36.3814Z"
      fill="#59595B"
    />
    <path
      d="M114.824 38.3383C114.824 38.8318 114.46 39.121 114.097 39.121C113.639 39.121 113.37 38.7297 113.37 38.3383V38.2362C113.007 38.7297 112.644 39.121 111.822 39.121C110.732 39.121 110.101 38.3383 110.101 37.062V34.2203C110.101 33.7268 110.464 33.4375 110.827 33.4375C111.285 33.4375 111.554 33.8289 111.554 34.2203V36.6706C111.554 37.3513 111.917 37.8448 112.375 37.8448C112.912 37.8448 113.37 37.4534 113.37 36.6706V34.2203C113.37 33.7268 113.734 33.4375 114.097 33.4375C114.555 33.4375 114.824 33.8289 114.824 34.2203V38.3383Z"
      fill="#59595B"
    />
    <path
      d="M115.55 34.2203C115.55 33.7268 115.914 33.4375 116.277 33.4375C116.735 33.4375 117.004 33.8289 117.004 34.2203V34.3224C117.367 33.7438 117.825 33.4375 118.631 33.4375C119.815 33.4375 120.984 34.5095 120.984 36.2793C120.984 38.1341 119.8 39.121 118.631 39.121C117.904 39.121 117.367 38.7297 117.004 38.3383V40.091C117.004 40.5845 116.64 40.8737 116.277 40.8737C115.914 40.8737 115.55 40.4824 115.55 40.091V34.2203ZM119.547 36.2793C119.547 35.3944 119.01 34.6116 118.283 34.6116C117.651 34.6116 117.019 35.1902 117.019 36.0751C117.019 36.9599 117.556 37.5385 118.283 37.5385C119.01 37.9469 119.547 37.3513 119.547 36.2793Z"
      fill="#59595B"
    />
  </svg>
);

export default SVGAriadnaLogo;
