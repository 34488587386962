import React from 'react';
import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@emotion/react';
import { SVGCheckCircle } from 'assets/svg';

export const HeroInfo = () => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Box
        component={'section'}
        sx={{
          padding: {
            sm: '1.5rem',
            md: '1.8rem',
            lg: '2.5rem',
            xl: '3.5rem',
          },
          display: 'flex',
          gap: '1rem',
          flexFlow: 'column nowrap',
        }}
      >
        <Container fixed>
          <Typography gutterBottom component="h1" variant="h4" sx={{ fontWeight: 700 }}>
            <FormattedMessage id={'heroInfo.title.h1'} />
          </Typography>
          <Typography
            component="p"
            variant="h5"
            sx={{ color: 'text.secondary' }}
          >
            <FormattedMessage id={'heroInfo.description.p'} />
          </Typography>
        </Container>
      </Box>
      <Box
        component={'section'}
        sx={{
          padding: {
            sm: '1.5rem',
            md: '1.8rem',
            lg: '2.5rem',
            xl: '3.5rem',
          },
          display: 'flex',
          gap: '1rem',
          flexFlow: 'column nowrap',
          alignItems: 'center',
          backgroundColor: theme.palette.background.level3,
        }}
      >
        <Container fixed>
          <Grid container center>
            <Grid size={{ xs: 12, lg: 8 }}>
              <Typography
                gutterBottom
                component="p"
                variant={'h5'}
                sx={{ color: theme.palette.primary.contrastText }}
              >
                <FormattedMessage id={'heroInfo.description2.p'} />
              </Typography>
            </Grid>
          </Grid>
          <List dense={true} disablePadding={true}>
            <ListItem disablePadding={true}>
              <ListItemIcon>
                <SVGCheckCircle />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  gutterBottom
                  component="p"
                  variant="h6"
                  sx={{ color: theme.palette.primary.contrastText }}
                >
                  <FormattedMessage id={'heroInfo.description2.li1'} />
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem disablePadding={true}>
              <ListItemIcon>
                <SVGCheckCircle />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  gutterBottom
                  component="p"
                  variant="h6"
                  sx={{ color: theme.palette.primary.contrastText }}
                >
                  <FormattedMessage id={'heroInfo.description2.li2'} />
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Container>
      </Box>
      <Box
        sx={{
          padding: {
            sm: '1.5rem',
            md: '1.8rem',
            lg: '2.5rem',
            xl: '3.5rem',
          },
          display: 'flex',
          gap: '1rem',
          justifyContent: 'center',
          alignItems: 'center',
          flexFlow: 'column nowrap',
          backgroundColor: theme.palette.background.papper,
        }}
      >
        <Typography
          textAlign="center"
          component="p"
          variant="h4"
          sx={{ fontWeight: 700, color: theme.palette.primary.main }}
        >
          <FormattedMessage id={'heroInfo.description3.p'} />
        </Typography>
      </Box>
    </React.Fragment>
  );
};
