import heroTextData from './components/Hero/lang';
import lastStoriesTextData from './components/LastStories/lang';

const blogReachTextDataES = {
  ...heroTextData.es,
  ...lastStoriesTextData.es
};
const blogReachTextDataEN = {
  ...heroTextData.en,
  ...lastStoriesTextData.en
};

export { blogReachTextDataEN, blogReachTextDataES };
