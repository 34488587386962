import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@emotion/react';
import { InputAdornment } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { FormattedMessage } from 'react-intl';

const Newsletter = () => {
  const theme = useTheme();
  return (
    <Box
      bgcolor={'secondary.contrastText'}
    >
      <Box marginBottom={4}>
        <Typography
          fontWeight={700}
          variant={'h5'}
          align={'center'}
          gutterBottom
          color={theme.palette.primary.contrastText}
        >
          <FormattedMessage id={'newsletter.title'} />
        </Typography>
        <Typography
          component={'p'}
          color={theme.palette.primary.contrastText}
          align={'center'}
        >
          <FormattedMessage id={'newsletter.p'} />
        </Typography>
      </Box>
      <Box maxWidth={600} margin={'0 auto'}>
        <Box
          component={'form'}
          noValidate
          autoComplete="off"
          sx={{
            '& .MuiInputBase-input.MuiOutlinedInput-input': {
              bgcolor: 'secondary.contrastText',
            },
          }}
        >
          <Box
            display="flex"
            flexDirection={{ xs: 'row', md: 'row' }}
            alignItems={{ xs: 'center', md: 'flex-start' }}
            justifyContent={{ xs: 'center' }}
          >
            <TextField
              flex={'2 1 auto'}
              label={
                <FormattedMessage id={'newsletter.placeholder'} />
              }
              variant="outlined"
              fullWidth
              height={44}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" bgcolor='secondary.contrastText'>
                    <NotificationsNoneIcon sx={{color: 'primary.contrastText', backgroundColor: 'secondary.contrastText'}}/>
                  </InputAdornment>
                ),
              }}
              sx={{
                color: `${theme.palette.primary}`,
                borderRadius: '0.3rem', 
                maxWidth: 422,
              }}
            />
            <Box
              component={Button}
              variant="contained"
              color="primary"
              size="large"
              height={54}
              marginLeft={2}
            >
              <FormattedMessage id={'newsletter.calltoaction'} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Newsletter;
