export const defaultLanguageHandler = () => {
  let language;
  const lastUserLanguage = window.localStorage.getItem('language');

  if (lastUserLanguage !== null) {
    language = lastUserLanguage;
    return language;
  }

  const explorerDefaultLanguage =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;

  const explorerLanguageWithoutRegion = explorerDefaultLanguage
    .toLowerCase()
    .split(/[_-]+/)[0];

  if (
    explorerLanguageWithoutRegion != 'es' &&
    explorerLanguageWithoutRegion != 'en'
  ) {
    language = 'en';
    localStorage.setItem('language', language);
    return language;
  }
  language = explorerLanguageWithoutRegion;
  localStorage.setItem('language', language);
  return language;
};

export const changeLanguage =
  ({ appLanguageSetter, textDataSetter, messagesData }) =>
    (newLanguage) => {
      const appAvailableLanguages = ['en', 'es'];
      if (appAvailableLanguages.includes(newLanguage) === false) {
        throw Error(
          `Set a correct language -> ${appAvailableLanguages.join(', ')}`,
        );
      }

      window.localStorage.setItem('language', newLanguage);
      appLanguageSetter(newLanguage);
      textDataSetter(messagesData[newLanguage]);
      return;
    };
