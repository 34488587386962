/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

import { FormattedMessage } from 'react-intl';

const Content = () => {
  const S3_URL = process.env.REACT_APP_S3_URL;

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        <Typography>
          <FormattedMessage id="articlecontent.3.paragraph.1"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              )
            }}
          />{' '}
        </Typography>
        <Typography
          variant={'h4'}
          align={'center'}
          fontWeight={'bold'}
          paddingTop={{ xs: 0, sm: 4, md: 6 }}
        >
          <FormattedMessage id="articlecontent.3.title.1"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              )
            }}
          />{' '}
        </Typography>
        <Typography paddingTop={{ xs: 0, sm: 2, md: 2 }}>
          <FormattedMessage id="articlecontent.3.paragraph.2"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              ),
              'ul': (...chunks) => (
                <ul>{chunks}</ul>
              ),
              'li': (...chunks) => (
                <li>{chunks}</li>
              ),
              'link-to-market': (...chunks) => (
                <a href="https://www.forbes.com/sites/forbesbusinesscouncil/2022/04/29/15-expert-recommended-strategies-for-keeping-up-with-an-ever-changing-market/?sh=4f7ca8da547b" target='_blank' rel="noreferrer">{chunks}</a>
              ),
              'link-to-automation': (...chunks) => (
                <a href="https://www.redhat.com/es/topics/automation" target='_blank' rel="noreferrer">{chunks}</a>
              )
            }}
          />{' '}
        </Typography>
        <Typography
          variant={'h6'}
          align={'left'}
          fontWeight={'bold'}
          paddingTop={{ xs: 0, sm: 4, md: 6 }}
        >
          <FormattedMessage id="articlecontent.3.title.2"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              )
            }}
          />{' '}
        </Typography>
        <Typography paddingTop={{ xs: 0, sm: 2, md: 2 }}>
          <FormattedMessage id="articlecontent.3.paragraph.3"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              ),
              'ul': (...chunks) => (
                <ul>{chunks}</ul>
              ),
              'li': (...chunks) => (
                <li>{chunks}</li>
              ),
              'link-to-marketing': (...chunks) => (
                <a href="https://mailchimp.com/es/resources/marketing-funnel/" target='_blank' rel="noreferrer">{chunks}</a>
              ),
              'external-cta': (...chunks) => (
                <a href="https://es.semrush.com/blog/que-son-cta-call-to-action/" target='_blank' rel="noreferrer">{chunks}</a>
              ),
              'link-to-blog': (...chunks) => (
                <a href="/blog/marketing-digital-potencia-canales-de-venta" rel="noreferrer">{chunks}</a>
              )
            }}
          />{' '}
        </Typography>
        <Typography
          variant={'h6'}
          align={'left'}
          fontWeight={'bold'}
          paddingTop={{ xs: 0, sm: 4, md: 6 }}
        >
          <FormattedMessage id="articlecontent.3.title.3"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              )
            }}
          />{' '}
        </Typography>
        <Typography paddingTop={{ xs: 0, sm: 2, md: 2 }}>
          <FormattedMessage id="articlecontent.3.paragraph.4"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              ),
              'ul': (...chunks) => (
                <ul>{chunks}</ul>
              ),
              'li': (...chunks) => (
                <li>{chunks}</li>
              ),
              'link-to-audience': (...chunks) => (
                <a href="https://rockcontent.com/blog/audience-profiling/" target='_blank' rel="noreferrer">{chunks}</a>
              ),
              'link-to-inbound': (...chunks) => (
                <a href="https://rockcontent.com/es/blog/inbound-marketing/" target='_blank' rel="noreferrer">{chunks}</a>
              )
            }}
          />{' '}
        </Typography>
        <Typography
          variant={'h4'}
          align={'center'}
          fontWeight={'bold'}
          paddingTop={{ xs: 0, sm: 4, md: 6 }}
        >
          <FormattedMessage id="articlecontent.3.title.4"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              )
            }}
          />{' '}
        </Typography>
        <Typography paddingTop={{ xs: 0, sm: 2, md: 2 }}>
          <FormattedMessage id="articlecontent.3.paragraph.5"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              ),
              'external-cta': (...chunks) => (
                <a href="https://www.iebschool.com/blog/marketing-automation-marketing-digital/" target='_blank' rel="noreferrer">{chunks}</a>
              ),
              'link-to-blog': (...chunks) => (
                <a href="/blog/paid-media-una-estrategia-para-mejorar-tu-negocio" rel="noreferrer">{chunks}</a>
              )
            }}
          />{' '}
        </Typography>
      </Box>
      <Box paddingY={4}>
        <Divider />
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
      >
        <Box display={'flex'} alignItems={'center'}>
          <Avatar
            sx={{ width: 50, height: 50, marginRight: 2 }}
            src={S3_URL + '/icono-autor.webp'}
          />
          <Box>
            <Typography fontWeight={600}>Merge</Typography>
            <Typography color={'text.secondary'}>Oct 13, 2023</Typography>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <Typography color={'text.secondary'}>
            <FormattedMessage id="articlecontent.share"
              values={{
                break: <br />,
                'bold-text': (...chunks) => (
                  <b>{chunks}</b>
                )
              }}
            />{' '}
          </Typography>
          <Box marginLeft={0.5}>
            <IconButton aria-label="Facebook">
              <FacebookIcon />
            </IconButton>
            <IconButton aria-label="Instagram">
              <InstagramIcon />
            </IconButton>
            <IconButton aria-label="Twitter">
              <TwitterIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
