import React from 'react';

const SVGCheckCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
    >
      <circle cx="10.7043" cy="11.1287" r="10.2537" fill="#377DFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3105 5.95764C17.7804 6.42495 17.7826 7.18476 17.3152 7.65471L8.72371 16.2948C8.49847 16.5213 8.19221 16.6487 7.87278 16.6487C7.55334 16.6487 7.24709 16.5213 7.02185 16.2948L4.09323 13.3497C3.62592 12.8797 3.62806 12.1199 4.09801 11.6526C4.56797 11.1853 5.32777 11.1874 5.79509 11.6574L7.87278 13.7468L15.6134 5.96242C16.0807 5.49247 16.8405 5.49033 17.3105 5.95764Z"
        fill="white"
      />
    </svg>
  );
};

export default SVGCheckCircle;
