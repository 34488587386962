const sidebarArticlesTextDataTwo = {
  en: {
    'sidebararticles.title.1':'Related entries',
    'sidebararticles.2.paragraph.1':'Digital marketing strategies to enhance your sales channels',
    'sidebararticles.read-more': 'Read more'
  },
  es: {
    'sidebararticles.title.1':'Entradas relacionadas',
    'sidebararticles.2.paragraph.1':'Estrategias de marketing digital para potenciar tus canales de venta',
    'sidebararticles.read-more': 'Leer más'
  }
};

export default sidebarArticlesTextDataTwo;
  