const heroContentTextData = {
  es: {
    'blogarticle.7.title':'Marketing omnicanal: Estrategia efectiva en educación',
    'blogarticle.7.date': '23 de Octubre de 2024'
  },
  en: {
    'blogarticle.7.title':'Omnichannel marketing: Effective strategy in education',
    'blogarticle.7.date': 'October 23, 2024'
  }
};

export default heroContentTextData;
  