import React from 'react';

const SVGLogoMergePlata = () => (
  <svg
    width="100%"
    height="auto"
    viewBox="0 0 686 149"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M304.05 109.862V66.8421C304.05 57.8221 301.99 50.2321 292.82 50.2321C283.96 50.2321 279.69 58.4621 279.69 67.3121V109.862H253.75V64.9421C253.75 55.9221 250.27 50.2321 242.52 50.2321C234.29 50.2321 229.55 57.6621 229.55 67.1621V109.872H203.45V31.1021H228.6V42.0121H228.92C232.56 35.3721 240.62 28.8821 252.49 28.8821C263.88 28.8821 271.79 33.7821 276.22 42.3321C281.44 34.1021 289.67 28.8821 301.37 28.8821C322.72 28.8821 330 46.2821 330 61.1521V109.872H304.05V109.862Z"
      fill="#E6E6E6"
    />
    <path
      d="M426.54 77.592H369.44C370.23 86.292 378.93 92.462 388.26 92.462C396.48 92.462 402.18 88.982 405.66 84.232L423.69 95.622C416.26 106.382 403.92 112.392 387.94 112.392C364.21 112.392 344.6 97.362 344.6 70.792C344.6 45.012 363.27 28.562 387.15 28.562C410.4 28.562 426.7 44.542 426.7 71.432C426.7 73.482 426.7 75.692 426.54 77.592ZM402.81 61.462C402.81 53.232 397.59 46.432 387.31 46.432C377.34 46.432 370.07 53.392 369.44 61.462H402.81Z"
      fill="#E6E6E6"
    />
    <path
      d="M492.4 52.2821C490.03 51.6521 487.81 51.4921 485.76 51.4921C471.84 51.4921 467.25 62.8821 467.25 69.3621V109.852H441.31V31.0821H466.3V42.4721H466.62C470.57 34.5621 478.32 28.8721 487.97 28.8721C490.03 28.8721 492.24 29.0321 493.51 29.5021L492.4 52.2821Z"
      fill="#E6E6E6"
    />
    <path
      d="M542.45 147.822C528.69 147.822 512.55 143.392 501.96 135.482L514.93 116.972C522.52 123.612 532.17 126.942 541.03 126.942C556.22 126.942 563.33 118.082 563.33 104.952V100.202H562.86C557.96 106.532 549.41 110.642 538.97 110.642C515.08 110.642 501.32 91.972 501.32 70.152C501.32 48.322 515.08 28.8621 538.81 28.8621C548.62 28.8621 558.42 32.8121 563.96 41.2021H564.28V31.0821H588.8V102.422C588.8 133.272 570.45 147.822 542.45 147.822ZM544.98 50.0721C533.27 50.0721 526.47 59.092 526.47 70.002C526.47 80.442 533.27 90.092 544.98 90.092C556.84 90.092 564.12 80.762 564.12 70.002C564.12 59.242 556.68 50.0721 544.98 50.0721Z"
      fill="#E6E6E6"
    />
    <path
      d="M685.34 77.592H628.24C629.03 86.292 637.73 92.462 647.06 92.462C655.28 92.462 660.98 88.982 664.46 84.232L682.49 95.622C675.06 106.382 662.72 112.392 646.74 112.392C623.01 112.392 603.4 97.362 603.4 70.792C603.4 45.012 622.07 28.562 645.95 28.562C669.2 28.562 685.5 44.542 685.5 71.432C685.5 73.482 685.5 75.692 685.34 77.592ZM661.61 61.462C661.61 53.232 656.39 46.432 646.11 46.432C636.14 46.432 628.87 53.392 628.24 61.462H661.61Z"
      fill="#E6E6E6"
    />
    <path
      d="M0 141.452V7.20201C0 1.56201 6.28 -1.79799 10.97 1.33201L111.86 68.632C116.05 71.432 116.05 77.592 111.85 80.382L10.96 147.332C6.26999 150.442 0 147.082 0 141.452Z"
      fill="#E6E6E6"
    />
    <path
      d="M46.9204 69.4721L147.96 1.37205C153.25 -2.18795 160.36 1.59206 160.36 7.97206V140.562C160.36 148.982 149 151.632 145.27 144.082L117.56 87.9621C116.37 85.5521 113.92 84.0321 111.23 84.0321H51.3604C43.5104 84.0221 40.4104 73.8521 46.9204 69.4721Z"
      fill="#5150FD"
    />
  </svg>
);

export default SVGLogoMergePlata;
