const articleContentTextData = {
  en: {
    'blogarticle.7.p.1.1': '<strong>Omni-channel communications</strong> and marketing is an essential strategy in the education sector, especially in a world where technology is advancing by leaps and bounds. Educational institutions face the challenge of maintaining effective and consistent communication with their students.',
    'blogarticle.7.p.1.2': 'This marketing offers an end-to-end solution that ensures a consistent and personalized experience across all touch points. In this article, we will explore how to develop a strategy that revolutionizes communication in the education sector.',
    'blogarticle.7.h2.2': 'What is <strong>omnichannel marketing</strong> in the education sector? ',
    'blogarticle.7.p.2': 'It refers to the integration and coordination of all the communication and marketing channels that an educational institution uses to interact with its students, parents and staff. Unlike multichannel marketing, where channels work independently, <strong>omnichannel marketing</strong> ensures that all channels work together to deliver a consistent and seamless experience.',
    'blogarticle.7.h2.3': 'Step 1: Identify and understand your audience',
    'blogarticle.7.p.3.': 'Before implementing this type of strategy, it is critical to thoroughly understand your audience. In the education sector, this includes students, parents, teachers and potential future students. Here, a <link-customerjourney>customer journey map</link-customerjourney> can be a valuable tool to visualize the different stages, and touch points your audience has with the institution. ',
    'blogarticle.7.h3.3.1': 'Key actions:',
    'blogarticle.7.ul.3.1': `
      <ul>
        <li><link-segmentacion><strong>Segmentation</strong></link-segmentacion>: divide your audience into segments based on demographics, behaviors and needs. Consider the differences between undergraduate students, graduate students, specialization courses, etc., to further personalize the experience.  </li>
        <li><strong>Create user profiles</strong>: develop detailed profiles for each segment, identifying their communication preferences and the channels they use most</li>
      </ul>
    `,
    'blogarticle.7.h2.4': 'Step 2: Select the right channels',
    'blogarticle.7.p.4': 'Once you know your audience, it\'s time to choose the most effective channels for each segment. The goal of omnichannel marketing is to be present in the channels your users prefer, whether it\'s <link-emailing>emailing</link-emailing>, social networks, mobile apps, or even face-to-face communication.',
    'blogarticle.7.h3.4.1': 'Channels to consider:',
    'blogarticle.7.ul.4.1': `
      <ul>
        <li>Email: ideal for formal and personalized communications.</li>
        <li>Social media: useful for maintaining a constant connection and sharing relevant content</li>
        <li>Mobile apps: perfect for quick notifications and reminders</li>
        <li>Web portals and learning platforms: crucial for day-to-day educational interaction</li>
      </ul>
    `,
    'blogarticle.7.h2.5': 'Step 3: Land the strategy based on the conversion funnel',
    'blogarticle.7.p.5': 'To maximize the effectiveness of omnichannel marketing, land the strategy considering each stage of the conversion funnel:',
    'blogarticle.7.ol.5': `
      <ol>
        <li><strong>Positioning and reach</strong>: increase the institution's visibility through organic optimization, digital campaigns and traditional advertising. In this phase, make sure the audience is aware of the features of your programs and courses.</li>
        <li><strong>Consideration</strong>: customize content to address specific concerns of your buyer persona. Empower channels according to the needs of each segment and offer concrete answers that help prospects move forward in their decision.</li>
        <li><strong>Conversion</strong>: focus on the direct offer, highlighting immediacy, discounts or exclusive benefits. This is the time to convince the prospect that your institution is the best option.</li>
      </ol>
    `,
    'blogarticle.7.h2.6': 'Step 4: Integrate Technology for Automation',
    'blogarticle.7.p.6': 'Automation is essential to an effective <strong>omnichannel marketing</strong> strategy. It allows you to send the right message, to the right person, at the right time, without overloading your team.',
    'blogarticle.7.h3.6': 'Automation tools:',
    'blogarticle.7.ul.6': `
      <ul>
        <li><strong>Educational CRM</strong>: helps manage and analyze interactions with students.</li>
        <li><strong>Marketing automation platforms</strong>: allow you to schedule and personalize messages across multiple channels.</li>
        <li><strong>Data analytics</strong>: monitor and adjust your strategy based on real-time performance.</li>
      </ul>
    `,
    'blogarticle.7.h2.7': 'Step 5: Monitor and optimize continuously ',
    'blogarticle.7.p.7': 'These types of actions within your marketing strategies are not “one-time” actions, they require constant monitoring and adjustments based on data analysis.',
    'blogarticle.7.h3.7': 'Steps to optimization',
    'blogarticle.7.ul.7': `
      <ul>
        <li><strong>Evaluate performance</strong>: regularly review key metrics for each channel.</li>
        <li><strong>Gather feedback</strong>: listen to user opinions to improve the experience.</li>
        <li><strong>Adapt the strategy</strong>: adjust your tactics based on the results obtained.</li>
      </ul>
    `,
    'blogarticle.7.p.7.1': 'Implementing omnichannel marketing strategies in the education sector may seem challenging, but the benefits are immense. By knowing your audience, choosing the right channels, and continually optimizing, you can transform your institution\'s communication.',
    'blogarticle.7.p.7.2': 'Not only will you transform the student experience, but you will also strengthen your institution\'s image. At Merge, we integrate marketing and sales in an end-to-end model that optimizes your omnichannel communication. With our technology solutions, such as CRM, we ensure seamless coordination across all touch points. <link-contact>Contact us here</link-contact>.',
  },
  es: {
    'blogarticle.7.p.1.1': 'Las comunicaciones y el <strong>Marketing omnicanal</strong> es una estrategia esencial en el sector educativo, especialmente en un mundo donde la tecnología avanza a pasos agigantados. Las instituciones educativas se enfrentan al reto de mantener una comunicación efectiva y coherente con sus estudiantes.',
    'blogarticle.7.p.1.2': 'Este marketing ofrece una solución integral que asegura una experiencia uniforme y personalizada en todos los puntos de contacto. En este artículo, exploraremos cómo desarrollar una estrategia que revolucione la comunicación en el sector educativo.',
    'blogarticle.7.h2.2': '¿Qué es el <strong>marketing omnicanal</strong> en el sector educativo?',
    'blogarticle.7.p.2': 'Se refiere a la integración y coordinación de todos los canales de comunicación y marketing que una institución educativa utiliza para interactuar con sus estudiantes, padres y personal. A diferencia del <link-multicanal>marketing multicanal</link-multicanal>, donde los canales funcionan de manera independiente, el <strong>marketing omnicanal</strong> asegura que todos los canales trabajen juntos para ofrecer una experiencia consistente y sin fisuras.',
    'blogarticle.7.h2.3': 'Paso 1: Identificar y entender a tu audiencia',
    'blogarticle.7.p.3.': 'Antes de implementar este tipo de estrategias, es fundamental conocer a fondo a tu audiencia. En el sector educativo, esto incluye a estudiantes, padres, profesores y posibles futuros alumnos. Aquí, un <link-customerjourney>customer journey map</link-customerjourney> puede ser una herramienta valiosa para visualizar las diferentes etapas y puntos de contacto que tu audiencia tiene con la institución.',
    'blogarticle.7.h3.3.1': 'Acciones clave:',
    'blogarticle.7.ul.3.1': `
      <ul>
        <li><link-segmentacion><strong>Segmentación</strong></link-segmentacion>: divide tu audiencia en segmentos basados en características demográficas, comportamientos y necesidades. Considera las diferencias entre estudiantes de pregrado, posgrado, cursos de especialización, etc., para personalizar aún más la experiencia. </li>
        <li><strong>Creación de perfiles de usuario</strong>: desarrolla perfiles detallados para cada segmento, identificando sus preferencias de comunicación y los canales que más utilizan. </li>
      </ul>
    `,
    'blogarticle.7.h2.4': 'Paso 2: Seleccionar los canales adecuados',
    'blogarticle.7.p.4': 'Una vez que conoces a tu audiencia, es momento de elegir los canales más efectivos para cada segmento. El objetivo del <strong>marketing omnicanal</strong> es estar presente en los canales que tus usuarios prefieren, ya sea <link-emailing>emailing</link-emailing>, redes sociales, aplicaciones móviles, o incluso comunicación presencial. ',
    'blogarticle.7.h3.4.1': 'Canales a considerar:',
    'blogarticle.7.ul.4.1': `
      <ul>
        <li><strong>Correo electrónico</strong>: ideal para comunicaciones formales y personalizadas </li>
        <li><strong>Redes sociales</strong>: útiles para mantener una conexión constante y compartir contenido relevante</li>
        <li><strong>Aplicaciones móviles</strong>: perfectas para notificaciones rápidas y recordatorios</li>
        <li><strong>Portales web y plataformas de aprendizaje</strong>: cruciales para la interacción educativa diaria</li>
      </ul>
    `,
    'blogarticle.7.h2.5': 'Paso 3: Aterrizar la estrategia en base al funnel de conversión',
    'blogarticle.7.p.5': 'Para maximizar la efectividad del <strong>marketing omnicanal</strong>, aterriza la estrategia considerando cada etapa del funnel de conversión:',
    'blogarticle.7.ol.5': `
      <ol>
        <li><strong>Posicionamiento y alcance</strong>: aumenta la visibilidad de la institución mediante optimización orgánica, campañas digitales y publicidad tradicional. En esta fase, asegúrate de que el público conozca las características de tus programas y cursos. </li>
        <li><strong>Consideración</strong>: personaliza el contenido para resolver inquietudes específicas de tu buyer persona. Potencia los canales según las necesidades de cada segmento y ofrece respuestas concretas que ayuden a los prospectos a avanzar en su decisión.</li>
        <li><strong>Conversión</strong>: enfócate en la oferta directa, destacando inmediatez, descuentos o beneficios exclusivos. Es el momento de convencer al prospecto de que tu institución es la mejor opción.</li>
      </ol>
    `,
    'blogarticle.7.h2.6': 'Paso 4: Integrar tecnología para la automatización',
    'blogarticle.7.p.6': 'La automatización es esencial para una estrategia de <strong>marketing omnicanal</strong> eficaz. Permite enviar el mensaje correcto, a la persona correcta, en el momento correcto, sin sobrecargar a tu equipo.',
    'blogarticle.7.h3.6': 'Herramientas de automatización: ',
    'blogarticle.7.ul.6': `
      <ul>
        <li><strong>CRM educativo</strong>: ayuda a gestionar y analizar las interacciones con los estudiantes. </li>
        <li><strong>Plataformas de automatización de marketing</strong>: permiten programar y personalizar mensajes en múltiples canales. </li>
        <li><strong>Análisis de datos</strong>: monitorea y ajusta tu estrategia basándote en el rendimiento en tiempo real. </li>
      </ul>
    `,
    'blogarticle.7.h2.7': 'Paso 5: Monitorear y optimizar continuamente',
    'blogarticle.7.p.7': 'Este tipo de acciones dentro de tus estrategias de marketing no son de "una sola vez", requiere monitoreo constante y ajustes basados en el análisis de datos.',
    'blogarticle.7.h3.7': 'Pasos para la optimización:',
    'blogarticle.7.ul.7': `
      <ul>
        <li><strong>Evaluar el rendimiento</strong>: revisa regularmente las métricas clave de cada canal. </li>
        <li><strong>Recoger feedback</strong>: escucha las opiniones de los usuarios para mejorar la experiencia. </li>
        <li><strong>Adaptar la estrategia</strong>: ajusta tus tácticas en función de los resultados obtenidos. </li>
      </ul>
    `,
    'blogarticle.7.p.7.1': 'Implementar estrategias de <strong>marketing omnicanal</strong> en el sector educativo puede parecer desafiante, pero los beneficios son inmensos. Al conocer a tu audiencia, elegir los canales adecuados, y optimizar continuamente, puedes transformar la comunicación de tu institución. ',
    'blogarticle.7.p.7.2': 'No solo transformarás la experiencia del estudiante, sino que también fortalecerás la imagen de tu institución. En <strong>Merge</strong>, integramos marketing y ventas en un modelo end-to-end que optimiza tu comunicación omnicanal. Con nuestras soluciones tecnológicas, como el CRM, aseguramos una coordinación perfecta en todos los puntos de contacto. <link-contact>Contáctanos aquí</link-contact>. ',
  }
};
  
export default articleContentTextData;
  