import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { alpha, useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { FormattedMessage } from 'react-intl';

/* import {
  SVGIconAgile,
  SVGIconPapper,
  SVGIconPapperCicle,
  SVGIconPerson,
} from 'assets/svg'; */
const S3_URL = process.env.REACT_APP_S3_URL;
const mock = [
  {
    title: 'landing.value.li.1',
    titleBold: 'landing.value.li.1.1',
    title2: 'landing.value.li.1.2',
    avatar: S3_URL + '/business-model-in-results.svg',
  },
  {
    title: 'landing.value.li.2',
    titleBold: 'landing.value.li.2.1',
    title2: 'landing.value.li.2.2',
    avatar: S3_URL + '/extension-of-your-team.svg',
  },
  {
    title: 'landing.value.li.3',
    titleBold: 'landing.value.li.3.1',
    title2: 'landing.value.li.3.2',
    avatar: S3_URL + '/automated-data.svg',
  },
  {
    title: 'landing.value.li.4',
    titleBold: 'landing.value.li.4.1',
    title2: 'landing.value.li.4.2',
    avatar: S3_URL + '/agile-methodologies.svg',
  },
];

const Services = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={6} mb={6} mt={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i}>
            <Box
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Box
                  component={Avatar}
                  width={90}
                  height={90}
                  marginBottom={2}
                  bgcolor={alpha(theme.palette.primary.main, 0.1)}
                  color={theme.palette.primary.main}
                >
                  <Box
                    component="img"
                    height={1 / 2}
                    width={1 / 2}
                    src={item.avatar}
                  />
                </Box>
                <Box>
                  <Box>
                    <Typography component={'p'} align={'center'}>
                      <FormattedMessage id={item.title} />{' '}
                      <Typography
                        component={'span'}
                        sx={{
                          fontWeight: 700,
                        }}
                      >
                        <FormattedMessage id={item.titleBold} />{' '}
                      </Typography>
                      <FormattedMessage id={item.title2} defaultMessage=" " />{' '}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
