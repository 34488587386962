/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Container from 'components/Container';
import { Button } from '@mui/material';
import Brands from './components/Brands';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

const FullScreenHeroWithLogoGrid = () => {
  return (
    <Box
      minHeight={'80vh'}
      display={'flex'}
      alignItems={'center'}
      bgcolor={'alternate.paper'}
      id={'experience'}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent={'center'}
            >
              <Box>
                <Typography
                  variant="h3"
                  align={'center'}
                  gutterBottom
                  sx={{
                    fontWeight: 900,
                  }}
                >
                  <FormattedMessage id={'landing.title.1'} />{' '}
                  <Typography
                    component={'span'}
                    variant="h3"
                    color={'primary'}
                    sx={{
                      fontWeight: 900,
                    }}
                  >
                    <FormattedMessage id={'landing.title.2'} />{' '}
                  </Typography>
                  <br />
                  <FormattedMessage id={'landing.title.3'} />{' '}
                </Typography>
                <Typography
                  variant="h6"
                  component={'p'}
                  color="text.secondary"
                  align={'center'}
                  sx={{
                    fontWeight: 400,
                  }}
                >
                  <FormattedMessage id={'landing.info.1'} />{' '}
                  <Typography
                    variant="h6"
                    component="span"
                    color="text.secondary"
                    align={'center'}
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    <FormattedMessage id={'landing.info.2'} /> <br />
                    <FormattedMessage id={'landing.info.3'} />{' '}
                  </Typography>
                  <FormattedMessage id={'landing.info.4'} />{' '}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Brands />
          </Grid>
          <Grid item xs={12}>
            <Box display={'flex'} justifyContent={'center'}>
              <Button
                variant={'contained'}
                component={RouterLink}
                to="/contact"
                size={'large'}
                endIcon={
                  <Box
                    component={'svg'}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={24}
                    height={24}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </Box>
                }
                id="botButton"
              >
                <FormattedMessage id={'landing.calltoaction.1'} />{' '}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FullScreenHeroWithLogoGrid;
