/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

import { FormattedMessage } from 'react-intl';

const Content = () => {
  const S3_URL = process.env.REACT_APP_S3_URL;

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        <Typography>
          <FormattedMessage id="articlecontent.6.paragraph.1"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              ),
              'i': (...chunks) => (
                <i>{chunks}</i>
              ),
              'link-to-blog': (...chunks) => (
                <a href="/blog/marketing-digital-potencia-canales-de-venta" rel="noreferrer">{chunks}</a>
              )
            }}
          />{' '}
        </Typography>
        <Typography
          variant={'h4'}
          align={'center'}
          fontWeight={'bold'}
          paddingTop={{ xs: 0, sm: 4, md: 6 }}
        >
          <FormattedMessage id="articlecontent.6.title.1"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              )
            }}
          />{' '}
        </Typography>
        <Typography
          variant={'h6'}
          align={'left'}
          fontWeight={'bold'}
          paddingTop={{ xs: 0, sm: 4, md: 6 }}
        >
          <FormattedMessage id="articlecontent.6.title.2"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              )
            }}
          />{' '}
        </Typography>
        <Typography paddingTop={{ xs: 0, sm: 2, md: 2 }}>
          <FormattedMessage id="articlecontent.6.paragraph.2"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              ),
              'italic-text': (...chunks) => (
                <i>{chunks}</i>
              ),
              'ul': (...chunks) => (
                <ul>{chunks}</ul>
              ),
              'li': (...chunks) => (
                <li>{chunks}</li>
              ),
              'external-link': (...chunks) => (
                <a href="https://www.zendesk.com.mx/blog/funnel-embudo-conversion/" target="_blank" rel="noreferrer">{chunks}</a>
              ),
            }}
          />{' '}
        </Typography>

        <Typography
          variant={'h6'}
          align={'left'}
          fontWeight={'bold'}
          paddingTop={{ xs: 0, sm: 4, md: 6 }}
        >
          <FormattedMessage id="articlecontent.6.title.3"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              )
            }}
          />{' '}
        </Typography>

        <Typography paddingTop={{ xs: 0, sm: 2, md: 2 }}>
          <FormattedMessage id="articlecontent.6.paragraph.3"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              ),
              'ol': (...chunks) => (
                <ol>{chunks}</ol>
              ),
              'li': (...chunks) => (
                <li>{chunks}</li>
              ),
              'external-cta': (...chunks) => (
                <a href="https://www.hotjar.com/es/seguimiento-web/comparar-el-trafico-web/" target='_blank' rel="noreferrer">{chunks}</a>
              ),
              'link-to-contact': (...chunks) => (
                <a href="/contact" rel="noreferrer">{chunks}</a>
              ),
              'link-to-contact-hub': (...chunks) => (
                <a href="https://meetings.hubspot.com/santiago-valencia1/agenda-merge" target='_blank' rel="noreferrer">{chunks}</a>
              ),
            }}
          />{' '}
        </Typography>

        <Typography
          variant={'h4'}
          align={'center'}
          fontWeight={'bold'}
          paddingTop={{ xs: 0, sm: 4, md: 6 }}
        >
          <FormattedMessage id="articlecontent.6.title.4"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              )
            }}
          />{' '}
        </Typography>
        
        <Typography
          variant={'h6'}
          align={'left'}
          fontWeight={'bold'}
          paddingTop={{ xs: 0, sm: 4, md: 6 }}
        >
          <FormattedMessage id="articlecontent.6.title.5"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              )
            }}
          />{' '}
        </Typography>
        <Typography paddingTop={{ xs: 0, sm: 2, md: 2 }}>
          <FormattedMessage id="articlecontent.6.paragraph.4"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              ),
              'ul': (...chunks) => (
                <ul>{chunks}</ul>
              ),
              'li': (...chunks) => (
                <li>{chunks}</li>
              ),
              'external-cta': (...chunks) => (
                <a href="https://www.questionpro.com/es/investigacion-de-mercados.html" target='_blank' rel="noreferrer">{chunks}</a>
              ),
              'link-to-cjm': (...chunks) => (
                <a href="/blog/customer-journey-en-la-industria-automotriz-conoce-su-importancia" target='_blank' rel="noreferrer">{chunks}</a>
              ),
              'link-to-contact-hub': (...chunks) => (
                <a href="https://meetings.hubspot.com/santiago-valencia1/agenda-merge" target='_blank' rel="noreferrer">{chunks}</a>
              ),
              'link-to-contact': (...chunks) => (
                <a href="/contact" rel="noreferrer">{chunks}</a>
              ),
            }}
          />{' '}
        </Typography>

        <Typography
          variant={'h4'}
          align={'center'}
          fontWeight={'bold'}
          paddingTop={{ xs: 0, sm: 4, md: 6 }}
        >
          <FormattedMessage id="articlecontent.6.title.6"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              )
            }}
          />{' '}
        </Typography>
        <Typography
          variant={'h6'}
          align={'left'}
          fontWeight={'bold'}
          paddingTop={{ xs: 0, sm: 4, md: 6 }}
        >
          <FormattedMessage id="articlecontent.6.title.7"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              )
            }}
          />{' '}
        </Typography>
        <Typography paddingTop={{ xs: 0, sm: 2, md: 2 }}>
          <FormattedMessage id="articlecontent.6.paragraph.5"
            values={{
              break: <br />,
              'bold-text': (...chunks) => (
                <b>{chunks}</b>
              ),
              'italic-text': (...chunks) => (
                <i>{chunks}</i>
              ),
              'external-link': (...chunks) => (
                <a href="https://www.oracle.com/co/cx/marketing/marketing-roi/" target='_blank' rel="noreferrer">{chunks}</a>
              ),
              'link-to-blog': (...chunks) => (
                <a href="/blog/paid-media-una-estrategia-para-mejorar-tu-negocio" rel="noreferrer">{chunks}</a>
              ),
              'ul': (...chunks) => (
                <ul>{chunks}</ul>
              ),
              'li': (...chunks) => (
                <li>{chunks}</li>
              ),
            }}
          />{' '}
        </Typography>
      </Box>
      <Box paddingY={4}>
        <Divider />
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
      >
        <Box display={'flex'} alignItems={'center'}>
          <Avatar
            sx={{ width: 50, height: 50, marginRight: 2 }}
            src={S3_URL + '/icono-autor.webp'}
          />
          <Box>
            <Typography fontWeight={600}>Merge</Typography>
            <Typography color={'text.secondary'}>Sept 23, 2024</Typography>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <Typography color={'text.secondary'}>
            <FormattedMessage id="articlecontent.share"
              values={{
                break: <br />,
                'bold-text': (...chunks) => (
                  <b>{chunks}</b>
                )
              }}
            />{' '}
          </Typography>
          <Box marginLeft={0.5}>
            <IconButton aria-label="Facebook">
              <FacebookIcon />
            </IconButton>
            <IconButton aria-label="Instagram">
              <InstagramIcon />
            </IconButton>
            <IconButton aria-label="Twitter">
              <TwitterIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
