import React from 'react';
import Grid from '@mui/material/Grid';

import Container from 'components/Container';
import { FeatureList, Cards } from './components';

const Product = () => {
  return (
    <Container>
      <Grid width={1} display={'flex'} spacing={4} flexDirection={'column'} justifyContent={'center'}>
        <Grid item container alignItems={'center'}  justifyContent={'center'} xs={12}>
          <FeatureList />
        </Grid>
        <Grid item  xs={12}>
          <Cards />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Product;
