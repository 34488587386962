import React from 'react';
import Slider from 'react-slick';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

const S3_URL = process.env.REACT_APP_S3_URL;
const mock = [
  {
    url: S3_URL + '/clientes/logo-kinto-share.svg',
    company: 'Kinto',
    maxWidth: 180,
    maxHeight: 60,
    marginRight: 0,
  },
  {
    url: S3_URL + '/clientes/logo-isil.svg',
    company: 'ISIL',
    maxWidth: 180,
    maxHeight: 60,
    marginRight: 5,
  },
  {
    url: S3_URL + '/clientes/logo-andes.svg',
    company: 'Universidad de los Andes',
    maxWidth: 180,
    maxHeight: 60,
    marginRight: 5,
  },
  {
    url: S3_URL + '/clientes/logo-ccb.svg',
    company: 'Cámara de Comercio',
    maxWidth: 180,
    maxHeight: 60,
    marginRight: 5,
  },
  {
    url: S3_URL + '/clientes/logo-pmi.svg',
    company: 'Phillip Morris International',
    maxWidth: 180,
    maxHeight: 60,
    marginRight: 5,
  },
  {
    url: S3_URL + '/clientes/logo-maresa.svg',
    company: 'Maresa',
    maxWidth: 180,
    maxHeight: 60,
    marginRight: 5,
  },
  {
    url: S3_URL + '/clientes/logo-british-council.svg',
    company: 'British Council',
    maxWidth: 180,
    maxHeight: 60,
    marginRight: 5,
  },
  {
    url: S3_URL + '/clientes/logo-traxion.svg',
    company: 'Tráxion Global',
    maxWidth: 180,
    maxHeight: 60,
    marginRight: 5,
  },
  {
    url: S3_URL + '/clientes/logo-san-martin.svg',
    company: 'San Martín',
    maxWidth: 180,
    maxHeight: 60,
    marginRight: 5,
  },
];
const Brands = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  let slidesToShow = 2;
  if (isXs) {
    slidesToShow = 2;
  }
  if (isSm) {
    slidesToShow = 3;
  }
  if (isMd) {
    slidesToShow = 4;
  }
  if (isLg) {
    slidesToShow = 5;
  }

  const sliderOpts = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <React.Fragment>
      <Box  py={6}>
        <Slider {...sliderOpts}>
          {mock.map((item, i) => (
            <Box
              maxWidth={item.maxWidth ? item.maxWidth : 120}
              marginX={item.marginX ? item.marginX : 3}
              marginY={item.marginY ? item.marginY : 0}
              key={i}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                component="img"
                height={1}
                width={1}
                src={item.url}
                alt={item.company}
              />
            </Box>
          ))}
        </Slider>
      </Box>
    </React.Fragment>
  );
};

export default Brands;
