/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import articleContentTextData from './lang.js';
import { useTheme } from '@emotion/react';

const dataValues = {
  'link-customerjourney':
    '/blog/customer-journey-en-la-industria-automotriz-conoce-su-importancia',
  'link-multicanal': 'https://blog.hubspot.es/marketing/marketing-multicanal',
  'link-segmentacion':
    'https://www.infoautonomos.com/estudio-de-mercado/segmentacion-de-mercados/',
  'link-emailing': 'blog/email-marketing-en-el-sector-educativo',
  'link-contact': 'https://www.mergeto.co/contact',
};
const variantStyle = {
  h2: 'h4',
  h3: 'h5',
  p: 'body1',
  ul: 'body1',
  ol: 'body1',
  li: 'body1',
};

const componentSubs = {
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  ul: 'div',
  ol: 'div',
  li: 'li',
  strong: 'strong',
  p: 'p',
};
const Content = () => {
  const theme = useTheme();
  const S3_URL = process.env.REACT_APP_S3_URL;
  
  const componentStyles = {
    h2: {
      textAlign: 'center',
      fontWeight: 700,
      width: '90%',
      margin: '2rem 0',
    },
    h3: {
      fontWeight: 700,
    },
    h4: {},
    h5: {},
    p: {
      margin: '2rem 0',
    },
    ul: {
      padding: '0',
    },
    li: {},
    strong: {
      fontWeight: '700',
    },
  };
  const structure = [];
  for (let key in articleContentTextData.es) {
    const [, , component] = key.split('.');
    structure.push(
      <Typography
        key={crypto.randomUUID()}
        component={componentSubs[component]}
        variant={variantStyle[component]}
        sx={{
          ...componentStyles[component],
          '& a': {
            color: theme.palette.text.primary,
          },
        }}
        gutterBottom
      >
        <FormattedMessage
          id={key}
          values={{
            strong: (...chunks) => <strong>{chunks}</strong>,
            li: (...chunks) => <li>{chunks}</li>,
            ul: (...chunks) => <ul>{chunks}</ul>,
            ol: (...chunks) => <ol>{chunks}</ol>,
            'link-contact': (...chunks) => (
              <a
                href={dataValues['link-contact']}
                target="_blank"
                rel="noreferrer"
              >
                {chunks}
              </a>
            ),
            'link-emailing': (...chunks) => (
              <Link to={dataValues['link-emailing']}>{chunks}</Link>
            ),
            'link-customerjourney': (...chunks) => (
              <Link to={dataValues['link-customerjourney']}>{chunks}</Link>
            ),
            'link-multicanal': (...chunks) => (
              <a
                href={dataValues['link-multicanal']}
                target="_blank"
                rel="noreferrer"
              >
                {chunks}
              </a>
            ),
            'link-segmentacion': (...chunks) => (
              <a
                href={dataValues['link-segmentacion']}
                target="_blank"
                rel="noreferrer"
              >
                {chunks}
              </a>
            ),
          }}
        />
      </Typography>,
    );
  }
  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        {structure}
      </Box>
      <Box paddingY={4}>
        <Divider />
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
      >
        <Box display={'flex'} alignItems={'center'}>
          <Avatar
            sx={{ width: 50, height: 50, marginRight: 2 }}
            src={S3_URL + '/icono-autor.webp'}
          />
          <Box>
            <Typography fontWeight={600}>Merge</Typography>
            <Typography color={'text.secondary'}>
              <FormattedMessage id={'blogarticle.7.date'} />
            </Typography>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <Typography color={'text.secondary'}>
            <FormattedMessage
              id="articlecontent.share"
              values={{
                break: <br />,
                'bold-text': (...chunks) => <b>{chunks}</b>,
              }}
            />{' '}
          </Typography>
          <Box marginLeft={0.5}>
            <IconButton aria-label="Facebook">
              <FacebookIcon />
            </IconButton>
            <IconButton aria-label="Instagram">
              <InstagramIcon />
            </IconButton>
            <IconButton aria-label="Twitter">
              <TwitterIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
