const pages = {
  landings: [
    {
      title: 'Experiencia',
      textIntId: 'navbar.experience',
      href: '/#experience',
      id: 'experience-nav',
    },
    {
      title: 'Nuestra promesa',
      textIntId: 'navbar.ourpromise',
      href: '/#promise',
      id: 'promise-nav'
    },
    {
      title: 'Modelo de negocio',
      textIntId: 'navbar.businessmodel',
      href: '/#business-model',
      id: 'business-nav'
    },
    {
      title: 'Aliados',
      textIntId: 'navbar.ally',
      href: '/#ally',
      id: 'ally-nav'
    },
    {
      title: 'Blog',
      textIntId: 'navbar.blog',
      href: '/blog',
      id: 'blog'
    },
    {
      title: 'Contacto',
      textIntId: 'navbar.contact',
      href: '/contact',
      id: 'contact'
    },
  ]
};

export default pages;
