import React from 'react';
import { Main } from 'layouts';
import { HeroImage } from './components/HeroImage/HeroImage';
import { HeroInfo } from './components/HeroInfo/HeroInfo';

const Contact = () => {
  return (
    <React.Fragment>
      <Main>
        <HeroImage />
        <HeroInfo />
      </Main>
    </React.Fragment>
  );
};

export default Contact;
