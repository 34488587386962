import React from 'react';
import PropTypes from 'prop-types';
const SVGIconAgile = ({ color }) => (
  <svg
    width="67"
    height="67"
    viewBox="0 0 67 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.98 24.5697L29.1825 36.3947L24.645 31.8572C24.3985 31.5693 24.0951 31.3355 23.7539 31.1705C23.4128 31.0054 23.0411 30.9127 22.6624 30.898C22.2837 30.8834 21.906 30.9472 21.5531 31.0855C21.2002 31.2237 20.8797 31.4334 20.6117 31.7014C20.3437 31.9694 20.134 32.2899 19.9958 32.6428C19.8575 32.9957 19.7937 33.3734 19.8083 33.7521C19.8229 34.1308 19.9157 34.5025 20.0808 34.8436C20.2458 35.1848 20.4796 35.4882 20.7675 35.7347L27.23 42.2247C27.487 42.4796 27.7917 42.6812 28.1268 42.8181C28.4618 42.9549 28.8206 43.0243 29.1825 43.0222C29.9039 43.0192 30.5953 42.7328 31.1075 42.2247L44.8575 28.4747C45.1153 28.2191 45.3199 27.9149 45.4595 27.5798C45.5991 27.2447 45.671 26.8853 45.671 26.5222C45.671 26.1592 45.5991 25.7997 45.4595 25.4646C45.3199 25.1295 45.1153 24.8254 44.8575 24.5697C44.3423 24.0575 43.6453 23.77 42.9188 23.77C42.1923 23.77 41.4953 24.0575 40.98 24.5697ZM33.5 5.89722C28.061 5.89722 22.7442 7.51006 18.2218 10.5318C13.6995 13.5535 10.1747 17.8485 8.09333 22.8734C6.01192 27.8984 5.46732 33.4277 6.52842 38.7622C7.58951 44.0967 10.2086 48.9967 14.0546 52.8427C17.9005 56.6886 22.8006 59.3077 28.135 60.3688C33.4695 61.4299 38.9988 60.8853 44.0238 58.8039C49.0488 56.7225 53.3437 53.1978 56.3654 48.6754C59.3872 44.153 61 38.8362 61 33.3972C61 29.7859 60.2887 26.2099 58.9067 22.8734C57.5247 19.537 55.4991 16.5054 52.9454 13.9518C50.3918 11.3982 47.3603 9.37253 44.0238 7.99053C40.6874 6.60853 37.1114 5.89722 33.5 5.89722ZM33.5 55.3972C29.1488 55.3972 24.8953 54.1069 21.2775 51.6895C17.6596 49.2722 14.8398 45.8362 13.1747 41.8162C11.5095 37.7963 11.0739 33.3728 11.9227 29.1052C12.7716 24.8376 14.8669 20.9176 17.9437 17.8409C21.0204 14.7641 24.9404 12.6688 29.208 11.8199C33.4756 10.9711 37.8991 11.4067 41.919 13.0719C45.939 14.737 49.375 17.5568 51.7923 21.1747C54.2097 24.7926 55.5 29.046 55.5 33.3972C55.5 39.232 53.1822 44.8278 49.0564 48.9536C44.9306 53.0794 39.3348 55.3972 33.5 55.3972Z"
      fill={color}
    />
  </svg>
);

SVGIconAgile.propTypes = {
  color: PropTypes.string
};

SVGIconAgile.defaultProps = {
  color: '#000'
};

export default SVGIconAgile;
