import React from 'react';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import { Container } from '@mui/system';
import { Main } from 'layouts';
import Product from './components/Product';
import FullScreenHeroWithLogoGrid from './components/FullScreenHeroWithLogoGrid';
import Partners from './components/Partners';
import Team from './components/Team';

const Landing = () => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Main>
        <Box backgroundColor={theme.palette.background.level1}>
          <FullScreenHeroWithLogoGrid />
        </Box>

        {/* <Box backgroundColor={theme.palette.background.level1} paddingY={10}>
          <Container>
            <WithBorderedCardsAndBrandColor />
          </Container>
        </Box> */}
        <Box 
          backgroundColor={theme.palette.background.paper} 
          paddingY={10} 
          id={'promise'}
        >
          <Container>
            <Team />
          </Container>
        </Box>
        <Box 
          paddingY={5} 
          backgroundColor={theme.palette.background.level1} 
          id={'business-model'}
        >
          <Container>
            <Product />
          </Container>
        </Box>
        {/*         <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: '100%',
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.level1}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box> */}
        <Box bgcolor="primary" paddingTop={1} id={'ally'}>
          <Partners />
        </Box>
        {/* <Box>
          <Container>
            <SpanningColumns />
          </Container>
        </Box> */}
        {/* <Box>
          <Container>
            <ClientSatisfaction />
          </Container>
        </Box> */}
      </Main>
    </React.Fragment>
  );
};

export default Landing;
