import { Box, Grid } from '@mui/material';
import { Main } from 'layouts';
import React from 'react';
import Form from './components/Form';

const Contact = () => {
  return (
    <React.Fragment>
      <Main>
        <Grid container spacing={2} justifyContent={'center'}>
          <Grid item xs={12} sm={8} md={5} >
            <Box padding={4} paddingTop={3}>
              <Form />
            </Box>
          </Grid>
        </Grid>
      </Main>
    </React.Fragment>
  );
};

export default Contact;
