import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import Page from './components/Page';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-lightbox/style.css';
import 'aos/dist/aos.css';
import { LangProvider } from 'store/lang/LangProvider';
const App = () => {
  return (
    <LangProvider>
      <Page>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </Page>
    </LangProvider>
  );
};

export default App;
