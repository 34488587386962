/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { alpha } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
const S3_URL = process.env.REACT_APP_S3_URL;
const mock = [
  {
    color: '#5150FD',
    titleId: 'landing.product.cards.title.1',
    subtitleId:'landing.product.cards.text.1',
    paddingBottom: {
      xs: 1, 
      sm: 4,
      md: 2
    },
    icon: (
      <Box
        component={'img'}
        src={S3_URL + '/number-one-prospects.svg'}
        height={90}
        width={90}
      />
    ),
  },
  {
    color: '#131250',
    titleId: 'landing.product.cards.title.2',
    subtitleId:'landing.product.cards.text.2',
    paddingBottom: {
      xs: 1, 
      sm: 2,
      md: 2
    },
    icon: (
      <Box
        component={'img'}
        src={S3_URL + '/number-two-engagement.svg'}
        height={90}
        width={90}
      />
    )
  },
  {
    color: '#5150FD',
    titleId: 'landing.product.cards.title.3',
    subtitleId:'landing.product.cards.text.3',
    paddingBottom: {
      xs: 1, 
      sm: 3,
      md: 2
    },
    icon: (
      <Box
        component={'img'}
        src={S3_URL + '/number-three-sales.svg'}
        height={90}
        width={90}
      />
    ),
  },
  {
    color: '#131250',
    titleId: 'landing.product.cards.title.4',
    subtitleId:'landing.product.cards.text.4',
    paddingBottom: {
      xs: 1, 
      sm: 6,
      md: 8
    },
    icon: (
      <Box
        component={'img'}
        src={S3_URL + '/number-four-loyalty.svg'}
        height={90}
        width={90}
      />
    ),
  },
];

const Cards = () => {
  return (
    <Grid container spacing={2} marginTop={3}>
      {mock.map((item, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Box
            display={'block'}
            width={1}
            height={1}
            /* sx={{
              transform: { md: `translateY(${30 * index}px)` }
            }} */
          >
            <Box component={Card}  width={1} height={1}>
              <Box display={'flex'} flexDirection={'column'}>
                <Box
                  component={Avatar}
                  width={1}
                  height={200}
                  marginBottom={1}
                  bgcolor={alpha(item.color, 0.9)}
                  color={item.color}
                  variant={'rounded'}
                >
                  {item.icon}
                </Box>
                <Box
                  padding={4}
                  paddingBottom={item.paddingBottom}
                  paddingTop={1}
                >
                  <Typography
                    variant={'h6'}
                    gutterBottom
                    sx={{ fontWeight: 700 }}
                  >
                    <FormattedMessage id={item.titleId} />
                  </Typography>
                  <Typography color="text.secondary">
                    <FormattedMessage id={item.subtitleId} />
                  </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default Cards;
