const heroInfoDataES = {
  'heroInfo.title.h1': 'Hemos recibido tu información correctamente.',
  'heroInfo.description.p': 'Muy pronto uno de nuestros especialistas se pondrá en contacto contigo para encontrar juntos la mejor solución a tu negocio.',
  'heroInfo.description2.p': 'Mientras tanto, te invitamos a explorar más sobre nosotros o descubrir nuestros últimos recursos:',
  'heroInfo.description2.li1': 'Visita nuestro blog para obtener más consejos útiles.',
  'heroInfo.description2.li2': 'Síguenos en redes sociales para estar al tanto de nuestras novedades.',
  'heroInfo.description3.p': 'Estamos emocionados de poder ayudarte'
};
const heroInfoDataEN = {
  'heroInfo.title.h1': 'We have received your information correctly.',
  'heroInfo.description.p': 'One of our specialists will contact you very soon to find the best solution for your business.',
  'heroInfo.description2.p': 'In the meantime, we invite you to explore more about us or discover our latest resources:',
  'heroInfo.description2.li1': 'Visit our blog for more helpful tips.',
  'heroInfo.description2.li2': 'Follow us on social media to stay up to date with our latest news.',
  'heroInfo.description3.p': 'We are excited to help you'
};

export { heroInfoDataES, heroInfoDataEN };
