import { Box } from '@mui/material';
import React from 'react';
export const HeroImage = () => {
  return (
    <Box
      component="div"
      sx={{
        backgroundColor: '#FFC32D',
        background:
          'linear-gradient(45deg, rgba(255,202,49,1) 0%, rgba(255,202,47,1) 39%, rgba(250,187,43,1) 85%)',
        display: 'flex',
        justifyContent: 'center',
        '& img' : {
          objectFit: 'cover',
          objectPosition: 'top'
        }
      }}
    >
      <picture>
        <source
          srcSet="https://res.cloudinary.com/bryancloudinary/image/upload/v1729532667/img-thankyou-dekstop_flvmvx.webp"
          media="(min-width: 1220px)"
          width="1220"
          height="520"
        />
        <source
          srcSet="https://res.cloudinary.com/bryancloudinary/image/upload/v1729532667/img-thankyou-1020_shiekm.webp"
          media="(min-width: 1020px)"
          width="1020"
          height="520"
        />
        <source
          srcSet="https://res.cloudinary.com/bryancloudinary/image/upload/v1729532556/img-thankyou-720_puxruc.webp"
          media="(min-width: 720px)"
          width="720"
          height="320"
        />
        <source
          srcSet="https://res.cloudinary.com/bryancloudinary/image/upload/v1729532667/img-thankyou-520_rra3uf.webp"
          media="(min-width: 520px)"
          width="520"
          height="280"
        />
        <img
          alt=""
          src="https://res.cloudinary.com/bryancloudinary/image/upload/v1729532667/img-thankyou-375_dqrhcs.webp"
          className="thanyou__image"
          width="375"
          height="220"
          loading="eager"
        />
      </picture>
    </Box>
  );
};
