import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { HashLink } from 'react-router-hash-link';
import { FormattedMessage } from 'react-intl';

const NavItem = ({ item, colorInvert = false }) => {
  const theme = useTheme();

  const linkColor = colorInvert ? 'common.white' : 'text.primary';
  return (
    <Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        aria-describedby={item.id}
        sx={{
          cursor: 'pointer',
          borderRight:
            item.title == 'Herramientas'
              ? '0'
              : `1px solid ${theme.palette.text.secondary}`,
          paddingX: '20px',
        }}
      >
        <HashLink 
          to={item.href}
          smooth
          style={{textDecoration: 'none'}}
        >
          <Typography 
            fontWeight={400} 
            color={linkColor} 
            fontSize={'0.85rem'}
          >
            <FormattedMessage id={item.textIntId} />
          </Typography>
        </HashLink>
      </Box>
    </Box>
  );
};

NavItem.propTypes = {
  item: PropTypes.object.isRequired,
  colorInvert: PropTypes.bool,
};

export default NavItem;
