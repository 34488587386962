import heroContentTextData from './components/Hero/lang';
import articleContentTextData from './components/Content/lang';
import sidebarArticlesTextData from './components/SidebarArticles/lang';

const blogArticleTextDataES = {
  ...heroContentTextData.es,
  ...articleContentTextData.es,
  ...sidebarArticlesTextData.es
};
const blogArticleTextDataEN = {
  ...heroContentTextData.en,
  ...articleContentTextData.en,
  ...sidebarArticlesTextData.en
};

export { blogArticleTextDataEN, blogArticleTextDataES };
