import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

import { FormattedMessage } from 'react-intl';

const S3_URL = process.env.REACT_APP_S3_URL;
const mock = [
  {
    image: S3_URL + '/marketing-omnicanal-estrategia-efectiva-en-educacion-es.webp',
    description: 'blogarticle.7.p.1.1',
    title: 'blogarticle.7.title',
    anchor: '/blog/marketing-omnicanal-estrategia-efectiva-en-educacion',
    author: {
      name: 'Merge',
      avatar: S3_URL + '/icono-autor.webp',
    },
    date: '23 Oct 2024',
  },
  {
    image: S3_URL + '/marketing-y-ventas-estrategias-para-coordinar-equipos-y-aumentar-ventas.webp',
    description: 'laststories.6.paragraph.2',
    title: 'laststories.6.paragraph.1',
    anchor: '/blog/marketing-y-ventas-estrategias-para-coordinar-equipos-y-aumentar-ventas',
    author: {
      name: 'Merge',
      avatar: S3_URL + '/icono-autor.webp',
    },
    date: '23 Sept 2024',
  },
  {
    image: S3_URL + '/link-building-guia-para-mejorar-tu-autoridad-de-dominio.webp',
    description: 'laststories.5.paragraph.2',
    title: 'laststories.5.paragraph.1',
    anchor: '/blog/link-building-guia-para-mejorar-tu-autoridad-de-dominio',
    author: {
      name: 'Merge',
      avatar: S3_URL + '/icono-autor.webp',
    },
    date: '16 Jul 2024',
  },
  {
    image: S3_URL + '/customer-journey-en-la-industria-automotriz-conoce-su-importancia.webp',
    description: 'laststories.4.paragraph.2',
    title: 'laststories.4.paragraph.1',
    anchor: '/blog/customer-journey-en-la-industria-automotriz-conoce-su-importancia',
    author: {
      name: 'Merge',
      avatar: S3_URL + '/icono-autor.webp',
    },
    date: '13 Jun 2024',
  },
  {
    image: S3_URL + '/email-marketing-en-el-sector-educativo.webp',
    description: 'laststories.3.paragraph.2',
    title: 'laststories.3.paragraph.1',
    anchor: '/blog/email-marketing-en-el-sector-educativo',
    author: {
      name: 'Merge',
      avatar: S3_URL + '/icono-autor.webp',
    },
    date: '10 Abr 2024',
  },
  {
    image: S3_URL + '/paid-media-una-estrategia-para-mejorar-tu-negocio.webp',
    description: 'laststories.2.paragraph.2',
    title: 'laststories.2.paragraph.1',
    anchor: '/blog/paid-media-una-estrategia-para-mejorar-tu-negocio',
    author: {
      name: 'Merge',
      avatar: S3_URL + '/icono-autor.webp',
    },
    date: '13 Oct 2023',
  },
  {
    image: S3_URL + '/equipo-trabajando-en-estrategia-de-marketing-digital.webp',
    description: 'laststories.1.paragraph.2',
    title: 'laststories.1.paragraph.1',
    anchor: '/blog/marketing-digital-potencia-canales-de-venta',
    author: {
      name: 'Merge',
      avatar: S3_URL + '/icono-autor.webp',
    },
    date: '21 Jul 2023',
  }
];

const LastStories = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
        marginBottom={4}
      >
        <Box>
          <Typography fontWeight={700} variant={'h6'} gutterBottom>
            <FormattedMessage id={'laststories.title.1'} />{' '}
          </Typography>
          <Typography color={'text.secondary'}>
            <FormattedMessage id={'laststories.title.2'} />{' '}
          </Typography>
        </Box>
        {/* <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
          <Box
            component={Button}
            variant="outlined"
            color="primary"
            size="large"
            marginLeft={2}
          >
            View all
          </Box>
        </Box> */}
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              component={'a'}
              href={item.anchor}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                boxShadow={4}
                display={'flex'}
                flexDirection={'column'}
                sx={{ backgroundImage: 'none' }}
              >
                <CardMedia
                  image={item.image}
                  title={item.title}
                  sx={{
                    height: { xs: 200 },
                    position: 'relative',
                  }}
                >
                  <Box
                    component={'svg'}
                    viewBox="0 0 2880 480"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      color: theme.palette.background.paper,
                      transform: 'scale(2)',
                      height: 'auto',
                      width: 1,
                      transformOrigin: 'top center',
                    }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z"
                      fill="currentColor"
                    />
                  </Box>
                </CardMedia>
                <Box component={CardContent} position={'relative'}>
                  <Typography variant={'h6'} gutterBottom>
                    <FormattedMessage 
                      id={item.title} 
                      values={{
                        strong: (...chunks) => <strong>{chunks}</strong>
                      }} 
                    />
                  </Typography>
                  <Typography color="text.secondary">
                    <FormattedMessage 
                      id={item.description} 
                      values={{
                        strong: (...chunks) => <strong>{chunks}</strong>
                      }}
                    />
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box padding={2} display={'flex'} flexDirection={'column'}>
                  <Box marginBottom={2}>
                    <Divider />
                  </Box>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      <Avatar
                        src={item.author.avatar}
                        sx={{ marginRight: 1 }}
                      />
                      <Typography color={'text.secondary'}>
                        {item.author.name}
                      </Typography>
                    </Box>
                    <Typography color={'text.secondary'}>
                      {item.date}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LastStories;
