const heroTextData = {
  en: {
    'blogreach.title.1':'Blog',
    'blogreach.title.2': 'Find out about the latest marketing news.'
  },
  es: {
    'blogreach.title.1':'Blog',
    'blogreach.title.2': 'Entérate de la actualidad en marketing.'
  }
};

export default heroTextData;
