import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import pages from 'layouts/navigation';
import { HashLink } from 'react-router-hash-link';
import { SVGLogoMergePlata } from 'assets/svg';
import { FormattedMessage } from 'react-intl';

const S3_URL = process.env.REACT_APP_S3_URL;
const Footer = () => {
  const theme = useTheme();
  const {
    landings: landingPages
  } = pages;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'column' }}
        >
          <Box
            display={'flex'}
            component="a"
            href="/"
            title="theFront"
            width={{xs: '180px' , sm: '120px'}}
          >
            <SVGLogoMergePlata />
          </Box>
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            width={1}
            flexDirection={{ xs: 'column', sm: 'row' }}
            marginY={2}
          >
            {landingPages.map((link, index) => (
              <HashLink
                to={link.href}
                key={index}
                sx={{
                  justifyContent: 'flex-start',
                  color: theme.palette.primary.contrastText,
                  fontWeight: 600,
                  textDecoration: 'none',
                  borderRight: {
                    xs: '0',
                    sm:
                      index == landingPages.length - 1
                        ? '0'
                        : `1px solid ${theme.palette.primary.contrastText}`,
                  },
                  
                }}
              >
                <Typography
                  component={'p'}
                  sx={{
                    color: theme.palette.primary.contrastText,
                    fontWeight: 400,
                    textDecoration: 'none',
                    padding: {
                      xs: '0',
                      sm: '0 10px'
                    },
                    fontSize: {
                      xs: '0.85rem',
                      sm: '0.7rem'
                    },
                    textAlign: 'center',
                    marginY: {
                      xs: 1,
                      sm: 0
                    }
                  }}
                >
                  <FormattedMessage id={link.textIntId} />
                </Typography>
              </HashLink>
            ))}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={{ xs: 'flex-start', sm: 'flex-end' }}
          width={1}
          flexDirection={{ xs: 'column', sm: 'column' }}
          marginY={{ xs: 0, sm: 2 }}
        >
          <Box
            display={'flex'}
            component="a"
            href="https://www.linkedin.com/company/merge-to/"
            taget="_blank"
            rel="noopener noreferrer"
            title="LinkedIn"
            width={{xs: '40px' , sm: '30px'}}
            marginY={2}
          >
            <Box
              component={'img'}
              src={S3_URL + '/linkedin-logo.webp'}
              height={1}
              width={1}
            />
          </Box>
          <Typography
            align={'center'}
            component={'p'}
            variant={'p'}
            color="primary.contrastText"
            fontSize={'0.65rem'}
            gutterBottom
          >
            &copy; <FormattedMessage id={'footer.rights'} />
          </Typography>
          <Typography
            align={'center'}
            component={'p'}
            variant={'p'}
            color="primary.contrastText"
            fontSize={'0.65rem'}
          >
            <FormattedMessage id={'footer.privacy'} />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
