const teamTextData = {
  en: {
    'landing.value.title.1': 'Our model ',
    'landing.value.title.2': ' adapts to different industries.',
    'landing.value.p.1': 'We are a team of ',
    'landing.value.p.2':
      'digital experts with more than 10 years of experience in helping businesses grow.',
    'landing.value.p.3':
      'We specialize in optimizing user experience, developing smarkerting strategies, ',
    'landing.value.p.4':
      'performance marketing, creating technology solutions, managing contact centers and ',
    'landing.value.p.5':
      'analyzing data to ensure the success of our clients.',
    'landing.value.li.1': 'Our business model is based on results. ',
    'landing.value.li.1.1': 'If you win, we win!',
    'landing.value.li.1.2': '',
    'landing.value.li.2': 'We are ',
    'landing.value.li.2.1': 'an extension of your marketing and sales team.',
    'landing.value.li.2.2': '',
    'landing.value.li.3': 'We measure, analyze and optimize ',
    'landing.value.li.3.1': 'in an automated way and in real time.',
    'landing.value.li.3.2': '',
    'landing.value.li.4': 'We work under ',
    'landing.value.li.4.1': 'agile methodologies.',
    'landing.value.li.4.2': ' '
  },
  es: {
    'landing.value.title.1': 'Nuestro modelo ',
    'landing.value.title.2': 'se adapta a diferentes industrias.',
    'landing.value.p.1': 'Somos un equipo de',
    'landing.value.p.2':
      'expertos digitales con más de 10 años de experiencia en impulsar el crecimiento de los negocios.',
    'landing.value.p.3':
      'Nos especializamos en optimizar la experiencia del usuario, desarrollar estrategias de smarkerting,',
    'landing.value.p.4':
      'performance marketing, crear soluciones tecnológicas, gestionar centros de contacto y',
    'landing.value.p.5':
      'analizar datos para garantizar el éxito de nuestros clientes.',
    'landing.value.li.1': 'Nuestro modelo de negocio está basado en resultados.',
    'landing.value.li.1.1': '¡Si tú ganas, nosotros ganamos!',
    'landing.value.li.1.2': '',
    'landing.value.li.2': 'Somos una',
    'landing.value.li.2.1': 'extensión de tu equipo de marketing y ventas.',
    'landing.value.li.2.2': '',
    'landing.value.li.3': 'Medimos, analizamos y optimizamos ',
    'landing.value.li.3.1': 'de manera automatizada y en tiempo real.',
    'landing.value.li.3.2': '',
    'landing.value.li.4': 'Trabajamos bajo',
    'landing.value.li.4.1': 'metodologías ágiles.',
    'landing.value.li.4.2': ' '
  },
};

export default teamTextData;
