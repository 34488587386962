const heroTextData = {
  en: {
    'landing.title.1':'Generating sales in ',
    'landing.title.2': 'digital channels ',
    'landing.title.3': 'is our specialty!',
    'landing.calltoaction.1': 'Contact us here',
    'landing.info.1': 'At Merge, we integrate your ',
    'landing.info.2': 'marketing and sales strategy through smarketing, ',
    'landing.info.3': 'optimizing your business results ',
    'landing.info.4': 'with the power of technology and the experience of our team.'
  },
  es: {
    'landing.title.1':'¡Generar ventas en ',
    'landing.title.2': 'canales digitales ',
    'landing.title.3': 'es nuestra especialidad!',
    'landing.calltoaction.1': 'Contáctanos acá',
    'landing.info.1': 'En Merge, integramos tu ',
    'landing.info.2': 'estrategia de marketing y ventas a través del smarketing, ',
    'landing.info.3': 'optimizando los resultados ',
    'landing.info.4': 'de tu negocio con el poder de la tecnología y la experiencia de nuestro equipo.'
  }
};

export default heroTextData;
