import heroContentTextDataSeven from './components/HeroSeven/lang';
import articleContentTextDataSeven from './components/ContentSeven/lang';
import sidebarArticlesTextDataSeven from './components/SidebarArticlesSeven/lang';

const blogArticleTextDataSevenES = {
  ...heroContentTextDataSeven.es,
  ...articleContentTextDataSeven.es,
  ...sidebarArticlesTextDataSeven.es
};
const blogArticleTextDataSevenEN = {
  ...heroContentTextDataSeven.en,
  ...articleContentTextDataSeven.en,
  ...sidebarArticlesTextDataSeven.en
};

export { blogArticleTextDataSevenEN, blogArticleTextDataSevenES };
