const formTextData = {
  en: {
    'form.title.1': 'Let\'s talk about your ',
    'form.title.2': 'business',
  },
  es: {
    'form.title.1': 'Hablemos de tu ',
    'form.title.2': 'negocio',
  },
};

export default formTextData;
