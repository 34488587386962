/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';

const S3_URL = process.env.REACT_APP_S3_URL;
const mock = [
  // {
  //   image: S3_URL + '/equipo-trabajando-en-estrategia-de-marketing-digital.webp',
  //   description: 'laststories.1.paragraph.2',
  //   title: 'laststories.1.paragraph.1',
  //   anchor: '/blog/marketing-digital-potencia-canales-de-venta',
  //   author: {
  //     name: 'Merge',
  //     avatar: S3_URL + '/icono-autor.webp',
  //   },
  //   date: '21 Jul 2023',
  // },
  {
    image: S3_URL + '/paid-media-una-estrategia-para-mejorar-tu-negocio.webp',
    description: 'laststories.2.paragraph.2',
    title: 'laststories.2.paragraph.1',
    anchor: '/blog/paid-media-una-estrategia-para-mejorar-tu-negocio',
    author: {
      name: 'Merge',
      avatar: S3_URL + '/icono-autor.webp',
    },
    date: '13 Oct 2023',
  },
  // {
  //   image: S3_URL + '/email-marketing-en-el-sector-educativo.webp',
  //   description: 'laststories.3.paragraph.2',
  //   title: 'laststories.3.paragraph.1',
  //   anchor: '/blog/email-marketing-en-el-sector-educativo',
  //   author: {
  //     name: 'Merge',
  //     avatar: S3_URL + '/icono-autor.webp',
  //   },
  //   date: '10 Abr 2024',
  // }
];

const SidebarArticles = () => {
  const theme = useTheme();
  return (
    <Box component={Card} variant={'outlined'} padding={2}>
      <Typography
        variant="h6"
        data-aos={'fade-up'}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        <FormattedMessage id={'sidebararticles.title.1'} />{' '}
      </Typography>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              boxShadow={0}
              borderRadius={0}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Box
                sx={{
                  width: { xs: 1, md: '50%' },
                }}
              >
                <Box
                  component={'img'}
                  loading="lazy"
                  height={1}
                  width={1}
                  src={item.image}
                  alt="..."
                  sx={{
                    objectFit: 'cover',
                    maxHeight: 120,
                    borderRadius: 2,
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.7)'
                        : 'none',
                  }}
                />
              </Box>
              <CardContent
                sx={{ padding: 1, '&:last-child': { paddingBottom: 1 } }}
              >
                <Typography fontWeight={700}>
                  <FormattedMessage id={`sidebararticles.${i+1}.paragraph.1`} />{' '}
                </Typography>
                <Box marginY={1 / 4}>
                  <Typography
                    variant={'caption'}
                    color={'text.secondary'}
                    component={'i'}
                  >
                    {item.author.name} - {item.date}
                  </Typography>
                </Box>
                <Button size={'small'}
                  component={RouterLink}
                  to={item.anchor}
                >
                  <FormattedMessage id={'sidebararticles.read-more'} />{' '}
                </Button>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SidebarArticles;
