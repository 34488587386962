import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import { NavItem } from './components';
import { SVGAriadnaLogo, SVGLogoMerge } from 'assets/svg';
import LanguageSelector from './components/LanguageSelector';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { landings: landingPages } = pages;

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box display={'flex'} alignItems={'center'} gap={3} title="theFront" width={{ xs: 240, md: 320 }}>
        <Link to="/" width={{xs:1, md:1/2}}>
          <Box height={1} width={1}>
            <SVGLogoMerge />
          </Box>
        </Link>
        <Typography
          align={'center'}
          component={'p'}
          variant={'p'}
          color="primary"
          fontSize={'0.75rem'}
        >
            BY
        </Typography>
        <a target="_blank" href="https://www.ariadnacommunicationsgroup.com/" rel="noreferrer noopener">
          <Box width={{xs: 70, md:100}} display={'flex'}>
            <SVGAriadnaLogo />
          </Box>
        </a>
      </Box>
      <Box
        sx={{ display: { xs: 'none', md: 'flex' } }}
        alignItems={'center'}
        paddingY={2}
      >
        {landingPages.map((item, index) => (
          <Box key={index}>
            <NavItem item={item} colorInvert={colorInvert} />
          </Box>
        ))}
        <Box marginRight={2} marginLeft={2}>
          <LanguageSelector />
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
