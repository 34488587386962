import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import globalMessages from './global';
import { changeLanguage, defaultLanguageHandler } from './utils';

const LangContext = React.createContext();

const LangProvider = ({ children }) => {
  const [textData, setTextData] = useState(() => {
    const textLanguage = defaultLanguageHandler();
    const data = globalMessages[textLanguage];
    return data;
  });

  const [appLanguage, setAppLanguage] = useState(() => {
    const lastUserLanguageOrExplorerPreferedLanguage = defaultLanguageHandler();
    return lastUserLanguageOrExplorerPreferedLanguage;
  });

  return (
    <LangContext.Provider
      value={{
        change: changeLanguage({
          appLanguageSetter: setAppLanguage,
          textDataSetter: setTextData,
          messagesData: globalMessages
        }),
        value: appLanguage,
      }}
    >
      <IntlProvider locale={appLanguage} messages={textData}>
        {children}
      </IntlProvider>
    </LangContext.Provider>
  );
};

LangProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { LangProvider, LangContext };
