const productTextData = {
  en: {
    'landing.product.title.1':
      'We integrate all operations into a single process',
    'landing.product.title.2': 'Do you know our End2End Performance model?',
    'landing.product.p1.1': 'We accompany you every step of the way! From',
    'landing.product.p1.2': 'the prospecting and contact phase to the successful completion of the sale.',
    'landing.product.p1.3':
      'This journey is supported by a deep data analysis, which allows us to',
    'landing.product.p1.4':
      'extract valuable learnings, implement continuous improvement actions and make informed business decisions.',
    'landing.product.p1.li.1':
      'Digital marketing channel strategy and planning',
    'landing.product.p1.li.2':
      'Digital media planning and acquisition focused on conversion, audience building and lead generation.',
    'landing.product.p1.li.3':
      'Digital ecosystem development (Landing pages, web and mobile chatbot, platform integration).',
    'landing.product.p1.li.4':
      'Contact center services (chatbot and call center).',
    'landing.product.p1.li.5': 
      'Content marketing.',
    'landing.product.p1.li.6': 
      'Digital media acquisition and planning focused on conversion, audience building and lead generation.',
    'landing.product.p1.li.7': 
      'Data intelligence (Automation, centralization, deployment, modeling, ML, AI).',
    'landing.product.p1.li.8':
      'Inbound Marketing Strategy, CRM implementation and Marketing Automation.',
    'landing.product.p2.1': 'N/A',
    'landing.product.p2.2': 'N/A',
    'landing.product.p2.3': 'N/A',
    'landing.product.p2.li.1': 'N/A',
    'landing.product.p2.li.2': 'N/A',
    'landing.product.p2.li.3': 'N/A',
    'landing.product.p2.li.4': 'N/A',
    'landing.product.cards.title.1': 'Prospects',
    'landing.product.cards.text.1':
      'We develop strategies to build segmented audiences, ensuring the generation of high quality leads.',
    'landing.product.cards.title.2': 'Relationship',
    'landing.product.cards.text.2':
      'We establish a connection with your customers at the right time and with the right message, thanks to personalization at every stage of the process.',
    'landing.product.cards.title.3': 'Sales',
    'landing.product.cards.text.3':
      'We implement conversion strategies, supported by technology and human resources, to ensure a successful business closing.',
    'landing.product.cards.title.4': 'Loyalty',
    'landing.product.cards.text.4':
      'We manage eCustomer Services, update databases, carry out surveys and develop loyalty plans.',
  },
  es: {
    'landing.product.title.1':
      'Integramos toda la gestión en solo un proceso',
    'landing.product.title.2': '¿Ya conoces nuestro modelo End2End Performance?',
    'landing.product.p1.1': '¡Te acompañamos en cada paso! Desde',
    'landing.product.p1.2': 'la fase de prospectación, y relacionamiento hasta la finalización exitosa de la venta.',
    'landing.product.p1.3':
      'Este recorrido lo respalda un análisis de datos profundo, lo que nos permite',
    'landing.product.p1.4':
      'extraer aprendizajes valiosos, implementar acciones de mejora continua y tomar decisiones comerciales informadas.',
    'landing.product.p1.li.1':
      'Estrategia y planeación del canal comercial digital',
    'landing.product.p1.li.2':
      'Planeación y compra de medios digitales enfocados en conversión, construcción de audiencias y generación de leads',
    'landing.product.p1.li.3':
      'Desarrollo de ecosistemas digitales (Landing pages, chatbot web y mobile, integraciones con plataformas)',
    'landing.product.p1.li.4':
      'Inteligencia de datos (Automatización, centralización, despliegue, modelos, ML, AI)',
    'landing.product.p1.li.5': 
      'Servicio de contact center (chatbot y voz)',
    'landing.product.p1.li.6': 
      'Estrategia de Inbound Marketing, implementación de CRM y Marketing Automation',
    'landing.product.p1.li.7': 
      'Marketing de contenidos',
    'landing.product.p1.li.8':
      'Estrategias de email marketing y lead nurturing',
    'landing.product.p2.1': 'Te ofrecemos',
    'landing.product.p2.2': 'tácticas con valor agregado',
    'landing.product.p2.3': 'en un entorno digital cambiante:',
    'landing.product.p2.li.1': 'Search Engine Optimization (SEO) ',
    'landing.product.p2.li.2':
      'Planeación y compra de medios digitales enfocados en conversión',
    'landing.product.p2.li.3': 'Implementación de procesos de automation y CRM',
    'landing.product.p2.li.4': 'Integraciones/Tagging (Martech y Adtech) ',
    'landing.product.cards.title.1': 'Prospectos',
    'landing.product.cards.text.1':
      'Desarrollamos estrategias para construir audiencias segmentadas, asegurando la generación de leads de alta calidad.',
    'landing.product.cards.title.2': 'Relacionamiento',
    'landing.product.cards.text.2':
      'Establecemos conexión con tus clientes en el momento preciso y mediante el mensaje adecuado, gracias a la personalización en cada fase del proceso.',
    'landing.product.cards.title.3': 'Venta',
    'landing.product.cards.text.3':
      'Implementamos estrategias de conversión, respaldadas por tecnología y equipo humano, para asegurar un cierre comercial exitoso.',
    'landing.product.cards.title.4': 'Fidelización',
    'landing.product.cards.text.4':
      'Administramos los eCustomer Services, actualizamos bases de datos, llevamos a cabo encuestas y desarrollamos planes de fidelización.',
  },
};

export default productTextData;

