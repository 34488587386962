import { heroInfoDataEN, heroInfoDataES } from './components/HeroInfo/lang';

const thankYouPageDataES = {
  ...heroInfoDataES,
};
const thankYouPageDataEN = {
  ...heroInfoDataEN,
};

export { thankYouPageDataEN, thankYouPageDataES };
