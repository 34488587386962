import heroTextData from './components/FullScreenHeroWithLogoGrid/lang';
import alliesTextData from './components/Partners/lang';
import productTextData from './components/Product/lang';
import teamTextData from './components/Team/lang';

const landingTextDataES = {
  ...heroTextData.es,
  ...alliesTextData.es,
  ...productTextData.es,
  ...teamTextData.es
};
const landingTextDataEN = {
  ...heroTextData.en,
  ...alliesTextData.en,
  ...productTextData.en,
  ...teamTextData.en
};

export { landingTextDataEN, landingTextDataES };
