const alliesTextData = {
  en: {
    'landing.allies.1': 'We optimize your experience with ',
    'landing.allies.2': 'the main technological platforms ',
    'landing.allies.3': 'in the market.',
  },
  es: {
    'landing.allies.1': 'Optimizamos tu experiencia con las ',
    'landing.allies.2': 'principales plataformas tecnológicas ',
    'landing.allies.3': 'del mercado.',
  },
};

export default alliesTextData;