import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { LangContext } from 'store/lang/LangProvider';

const Form = () => {
  const language = useContext(LangContext);
  
  useEffect(() => {
    const formIdByLanguage = {
      en: 'bf2b1bb5-6081-4b5d-a48c-54daea7e18c4',
      es: 'ed1e3f41-e3d4-40e1-884d-5afb305cc76a'
    };
    const script = document.createElement('script');
    script.src='//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '3942150',
          formId: formIdByLanguage[language.value],
          target: '#form-box',
        });
      }
    });
  }, [language.value]);
  return (
    <React.Fragment>
      <Box marginBottom={5}>
        <Typography
          variant="h4"
          component="h1"
          color="text.primary"
          align={'start'}
          sx={{
            fontWeight: 700,
          }}
        >
          <FormattedMessage id={'form.title.1'} />
          <Typography
            variant="h4"
            component="span"
            color="primary"
            align={'start'}
            sx={{
              fontWeight: 700,
            }}
          >
            <FormattedMessage id={'form.title.2'} />
          </Typography>
        </Typography>
      </Box>
      <div id="form-box"></div>
    </React.Fragment>
  );
};

export default Form;