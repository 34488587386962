import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { HashLink } from 'react-router-hash-link';
import { FormattedMessage } from 'react-intl';

const NavItem = ({ item }) => {

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'flex-start'}
        flexDirection={'column'}
        marginY={4}
        paddingY={0.5}
      >
        <HashLink 
          to={item.href}
          smooth
          style={{textDecoration: 'none'}}
        >
          <Typography
            fontWeight={400}
            color={'text.primary'}
          >
            <FormattedMessage id={item.textIntId} />
          </Typography>
        </HashLink>
      </Box>
    </Box>
  );
};

NavItem.propTypes = {
  item: PropTypes.array.isRequired,
  onClose: PropTypes.func,
};

export default NavItem;
