import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
/* import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia'; */
import Services from './components/Services';
import { FormattedMessage } from 'react-intl';

const Team = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          <FormattedMessage id={'landing.value.title.1'} />{' '}
          <Typography
            component={'span'}
            variant="h4"
            align={'center'}
            color={'primary'}
            sx={{
              fontWeight: 700,
            }}
          >
            <FormattedMessage id={'landing.value.title.2'} />
          </Typography>
        </Typography>
      </Box>
      <Box marginBottom={4}>
        <Typography variant="h6" color="text.secondary">
          <FormattedMessage id={'landing.value.p.1'} />{' '}
          <Typography
            variant="h6"
            color="text.secondary"
            component={'span'}
            sx={{
              fontWeight: 700,
            }}
          >
            <FormattedMessage id={'landing.value.p.2'} />{' '}
          </Typography>
          <FormattedMessage id={'landing.value.p.3'} />{' '}
          <Typography
            variant="h6"
            color="text.secondary"
            component={'span'}
            sx={{
              fontWeight: 700,
            }}
          >
            <FormattedMessage id={'landing.value.p.4'} />{' '}
          </Typography>
          <FormattedMessage id={'landing.value.p.5'} />{' '}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Services />
      </Grid>
    </Box>
  );
};

export default Team;
